import makeStyles from '@mui/styles/makeStyles'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { Theme } from '@mui/material/styles/createTheme'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import HomeIcon from '@mui/icons-material/Home'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import paths from '../../../data/paths.json'
import React, { useEffect, useState } from 'react'
import useToken from '../../../services/useToken'
import BackendApi, { Account, Organisation } from '../../../services/backend'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import ArticleIcon from '@mui/icons-material/Article'
import data from '../../../data/locale/en/data.json'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}))

export default function AddAccount() {
  const classes = useStyles()
  const history = useHistory()
  const { token } = useToken()
  const api = new BackendApi()
  // @ts-ignore
  let { id } = useParams()
  const location = useLocation()

  const [account, setAccount] = useState({
    organizationId: '',
    name: '',
    code: '',
    id,
  } as Account)
  const [organisations, setOrganisations] = useState<Organisation[]>([])

  useEffect(() => {
    api.getOrganisations(token).then((value) => {
      setOrganisations(value)
      const orgId = new URLSearchParams(location.search).get('organisationId')
      if (orgId) {
        setAccount({
          ...account,
          organizationId: orgId,
        })
      }
    })
    if (id !== undefined) {
      api.getAccountDetails(id, token).then((value) => {
        console.log('fetched account info: ' + JSON.stringify(value))
        setAccount({
          ...account,
          organizationId: value.organizationId,
          name: value.name,
          code: value.code,
          id: value.id,
        })
      })
    }
  }, [])

  function handleInputChange(
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<String>,
  ) {
    setAccount({
      ...account,
      [event.target.name]: event.target.value,
    })
    console.log('Update account: ' + JSON.stringify(account))
  }

  useEffect(() => {
    setAccount({
      ...account,
      code: account.name.toUpperCase().replaceAll(' ', '_'),
    })
  }, [account.name])

  function createAccount(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (id !== undefined) {
      console.log('account update: ' + JSON.stringify(account))
      api.updateAccount(account, token).then((r) => {
        history.push(paths.accounts)
      })
    } else {
      console.log('account created: ' + JSON.stringify(account))
      api.createAccount(account, token).then((r) => {
        if (r.status === 201) history.push(paths.accounts)
      })
    }
  }

  return (
    <Container component="form" onSubmit={(event) => createAccount(event)} maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/" className={classes.link}>
              <HomeIcon className={classes.icon} />
              {data.title.home}
            </Link>
            <Link color="inherit" to={paths.accounts}>
              <ArticleIcon className={classes.icon} />
              {data.accounts.title}
            </Link>
            <Typography color="textPrimary">
              {account.id !== undefined ? account.code : data.title.new}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="account-lbl">Organisation</InputLabel>
            <Select
              value={account.organizationId}
              labelId="organisation-lbl"
              id="organisation"
              name="organizationId"
              label="Organisation"
              onChange={(event) => handleInputChange(event)}
            >
              {organisations.map((value: Organisation) => (
                <MenuItem value={value.id}>{`${value.name} (${value.code})`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={account.name}
            onChange={(event) => handleInputChange(event)}
            id="name"
            name="name"
            label={data.wallets.field.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            disabled
            fullWidth
            value={account.code}
            onChange={(event) => handleInputChange(event)}
            id="code"
            name="code"
            label={data.wallets.field.code}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color={account.id !== undefined ? 'secondary' : 'primary'}
          >
            {account.id !== undefined ? data.btn.update : data.btn.create}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
