import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import useTransactions from '../services/useTransactions';

const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#E7E9ED'];

const MonthlyExpensesWidget: React.FC = () => {
    const expenseData = useTransactions();

    if (!expenseData) {
        return <p>Loading expenses...</p>;
    }

    // Prepare data for Recharts
    const chartData = expenseData.categories.map((category, index) => ({
        name: category,
        value: expenseData.amounts[index],
    }));

    return (
        <div>
            <h2>Monthly Expenses by Category</h2>
            <PieChart width={400} height={400}>
                <Pie
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </div>
    );
};

export default MonthlyExpensesWidget;
