import React, { useEffect, useState } from 'react'
import { AlertColor } from '@mui/material'
import { Link } from 'react-router-dom'
import ButtonGroup from '@mui/material/ButtonGroup'
import paths from '../../../data/paths.json'

import { Transaction, TransactionsApi } from '../../../api/index'
import useToken from '../../../services/useToken'
import data from '../../../data/locale/en/data.json'
import { GridColDef } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FintecyDataGrid from '../../../components/FintecyDataGrid'

function TransactionsPage() {
  const { token } = useToken()
  const api = new TransactionsApi()

  const [rows, setRows] = useState([] as Transaction[])
  const [msg, setMsg] = useState(
    data.msg.recordDeleted.replace('$type', data.transactions.title) as string,
  )
  const [alertType, setAlertType] = useState('success' as AlertColor)
  const [open, setOpen] = useState(false)

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') setOpen(false)
  }

  useEffect(() => {
    const options = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    api
      .getTransactions(options)
      .then((rsp: any) => setRows(rsp.data as Transaction[]))
      .catch((error: any) => {
        setAlertType('warning')
        setMsg(
          data.msg.failToLoadData.replace('$type', data.transactions.title) +
            ': ' +
            error,
        )
        setOpen(true)
      })
  }, [token, open])

  async function deleteTx(id: string) {
    const options = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    api
      .deleteTransaction(id, options)
      .then(() => {
        setAlertType('success')
        setMsg(data.msg.recordDeleted.replace('$type', data.transactions.title))
        setOpen(true)
      })
      .catch((reason) => {
        setMsg(reason)
        setAlertType('warning')
        setOpen(true)
      })
  }

  // @ts-ignore
  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: data.table.actions,
      description: 'supported operations',
      sortable: false,
      renderCell: (params) => (
        <ButtonGroup variant="text" color="primary" aria-label="wallets controls">
          <Tooltip title={data.btn.update}>
            <IconButton
              component={Link}
              to={paths.editTransaction.replace(':id', params.row.id || paths.new)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={data.btn.delete}>
            <IconButton onClick={() => deleteTx(params.row.id || '')}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
    {
      field: 'type',
      headerName: data.transactions.field.type,
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'ref',
      headerName: data.transactions.field.ref,
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'tradeTime',
      headerName: data.transactions.field.tradeTime,
      flex: 1,
      editable: false,
      renderCell: (params) => params.row.tradeDate.substring(11, 19),
    },
    {
      field: 'tradeDate',
      headerName: data.transactions.field.tradeDate,
      flex: 1,
      editable: false,
      renderCell: (params) => params.row.tradeDate.substring(0, 10),
    },
    {
      field: 'settlementDate',
      headerName: data.transactions.field.settlementDate,
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: (params) => params.row.settlementDate.substring(0, 10),
    },
    {
      field: 'amount',
      headerName: data.transactions.field.amount,
      flex: 1,
      type: 'number',
      editable: false,
    },
    {
      field: 'asset',
      headerName: data.transactions.field.asset,
      flex: 1,
      type: 'string',
      editable: false,
    },
  ]
  return (
    <FintecyDataGrid
      title={data.transactions.title}
      addNewLink={paths.addTransaction}
      rows={rows}
      columns={columns}
      openSnackbar={open}
      handleSnackbarClose={handleSnackbarClose}
      alertType={alertType}
      msg={msg}
    />
  )
}

export default TransactionsPage
