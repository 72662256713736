import { useState } from 'react'

export interface Token {
  accessToken: string
  refreshToken: string
}

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token') as string
    const userToken = JSON.parse(tokenString)
    return userToken?.accessToken
  }
  const [token, setToken] = useState(getToken())

  const saveToken = (userToken: Token): void => {
    localStorage.setItem('token', JSON.stringify(userToken))
    setToken(userToken.accessToken)
  }

  const clearToken = (logout: boolean = true): void => {
    if (logout) {
      localStorage.removeItem('token')
      setToken(undefined)
    } else {
      console.log("clear token")
    }
  }

  return {
    setToken: saveToken,
    deleteToken: clearToken,
    token,
  }
}
