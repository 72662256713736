import { useState } from 'react'

type Product = string

export default function useProductState() {
  const getProductState = () => {
    const state = localStorage.getItem('product')
    const defaultState = {
      name: 'ETH-USD',
      lastPrice: 0,
      change: 0,
    }
    return state ? JSON.parse(state) : defaultState
  }
  const [productState, setProductState] = useState(getProductState())

  const saveProduct = (product: Product) => {
    if (product.match('w*-w*')) {
      setProductState({
        product: product,
        lastPrice: productState.lastPrice,
        change: productState.change,
      })
      localStorage.setItem('product', JSON.stringify(productState))
    }
  }

  const saveProductPrice = (product: Product, lastPrice: number, change: number) => {
    if (product.match('w*-w*')) {
      setProductState({
        product: product,
        lastPrice: lastPrice,
        change: change,
      })
      localStorage.setItem('product', JSON.stringify(productState))
    }
  }

  return {
    productState,
    product: productState.product as string,
    last: productState.lastPrice as number,
    change: productState.change as number,
    setProduct: saveProduct,
    setProductPrice: saveProductPrice,
  }
}
