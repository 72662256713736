import PortfolioAggregates from '../components/PortfolioAggregates'
// import PropertyChart from '../components/properties/PropertyChart'
// import BackendApi from '../services/backend'
import { Grid } from '@mui/material'
import MonthlyExpensesWidget from "../components/MonthlyExpensesWidget";
import CumulativeExpensesWidget from "../components/CumulativeExpensesWidget";

export default function DashboardPage() {
  // const api = new BackendApi()
  // const properties = api.getPropertyContracts()
  return (
    <Grid container spacing={3} justifyContent="center">
      {/*{properties.map((value) => (*/}
      {/*  <Grid item sm={6} xs={12}>*/}
      {/*    <PropertyChart value={value} />*/}
      {/*  </Grid>*/}
      {/*))}*/}
      <Grid item sm={6} xs={12}>
        <MonthlyExpensesWidget />
      </Grid>
      <Grid item sm={6} xs={12}>
        <CumulativeExpensesWidget />
      </Grid>
    </Grid>
  )
}
