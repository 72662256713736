import { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import BackendApi from '../../services/backend'
import { Theme } from '@mui/material/styles/createTheme'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

export default function PasswordChangePanel() {
  const classes = useStyles()

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(event.target.value)
  }
  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value)
  }
  const handleRepeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(event.target.value)
  }

  const api = new BackendApi()

  const handlePasswordChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const res = api.changePassword(currentPassword, newPassword, repeatPassword)
    if (!res.changed) {
      console.log('Failed to change password ' + res.msg)
    } else {
      console.log('Password was changed: ' + res.msg)
    }
  }

  return (
    <form>
      <Grid container spacing={2} className={classes.centered}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            type="password"
            label="Current password"
            name="currentPassword"
            autoComplete="current-password"
            onChange={handleCurrentPasswordChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            type="password"
            label="New password"
            name="newPassword"
            onChange={handleNewPasswordChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            type="password"
            label="Repeat password"
            name="repeatPassword"
            onChange={handleRepeatPasswordChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handlePasswordChange}
          >
            Change password
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
