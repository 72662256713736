import LocationOnIcon from '@mui/icons-material/LocationOn'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import RestoreIcon from '@mui/icons-material/Restore'
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import ArticleIcon from '@mui/icons-material/Article'
import paths from '../data/paths.json'
import features from '../data/features.json'
import data from '../data/locale/en/data.json'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import LayersIcon from '@mui/icons-material/Layers'
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart'

export var miniApps = [
  {
    premium: false,
    path: paths.dashboard,
    name: data.title.dashboard,
    description: 'starting page of the app with favorite components',
    enabled: features.dashboard,
    icon: <DashboardIcon />,
  },
  {
    premium: false,
    path: paths.organisations,
    enabled: features.organisations,
    name: data.organisations.title,
    description: 'management of various counterparties',
    icon: <AccountBalanceIcon />,
  },
  {
    premium: false,
    path: paths.accounts,
    enabled: features.accounts,
    name: data.accounts.title,
    description: 'list of contracts with counterparties',
    icon: <ArticleIcon />,
  },
  {
    premium: false,
    path: paths.wallets,
    enabled: features.wallets,
    name: data.wallets.title,
    description: 'list of personal accounts',
    icon: <AccountBalanceWalletIcon />,
  },
  {
    premium: false,
    path: paths.transactions,
    enabled: features.transactions,
    name: data.transactions.title,
    description: 'personal financial ledger',
    icon: <SwapHorizontalCircleIcon />,
  },
  {
    premium: false,
    path: paths.propertyContracts,
    enabled: features.properties,
    name: data.properties.title,
    description: 'apartments for mortgage, tenancy or in ownership.',
    icon: <LocationOnIcon />,
  },
  {
    premium: false,
    path: paths.subscriptions,
    enabled: features.subscriptions,
    name: data.subscriptions.title,
    description: 'requiring payments.',
    icon: <RestoreIcon />,
  },
  {
    premium: false,
    path: paths.assets,
    enabled: features.assets,
    name: data.assets.title,
    description: 'currencies, commodities and crypto details',
    icon: <LocalAtmIcon />,
  },
  {
    premium: false,
    path: paths.quotes,
    enabled: features.quotes,
    name: data.quotes.title,
    description: 'market data updates for FX',
    icon: <CurrencyExchangeIcon />,
  },
  {
    premium: true,
    path: paths.trade,
    enabled: features.trade,
    name: data.terminal.title,
    description: 'Trading terminal',
    icon: <CandlestickChartIcon />,
  },
  {
    premium: true,
    path: paths.integrations,
    enabled: features.integrations,
    name: data.integrations.title,
    description: 'integrations with banks and brokerage companies',
    icon: <LayersIcon />,
  },
]
