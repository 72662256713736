import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { DesktopOnly, MobileOnly } from '../MobileDesktopVisibility'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import paths from '../../data/paths.json'
import BackendApi, { Address, Country } from '../../services/backend'
import { Property, Measure, Payment } from '../../model/modelTypes'
import data from '../../data/locale/en/data.json'
import { SelectChangeEvent } from '@mui/material/Select'
import useToken from '../../services/useToken'

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
type Params = {
  open: boolean
  setOpenAddProperty: (open: boolean) => void
}

export interface TenancyContractState {
  activeStep: number
  startDate: string
  endDate: string
  deposit: number
  monthlyPayment: number
  asset: string
  country: string
  propertyType: string
  addressSearch: string
  address: Address
  payments: Payment[]
  measures: Measure[]
}

export default function AddPropertyDialog(props: Params) {
  const classes = useStyles()
  const history = useHistory()

  const { token } = useToken()
  const api = new BackendApi()

  const [types] = useState(api.getMeasureTypes() as string[])
  const [propertyTypes, setPropertyTypes] = useState([] as string[])
  const [countries, setCountries] = useState<Country[]>([] as Country[])
  const [paymentTypes, setPaymentsTypes] = useState([] as string[])
  useEffect(() => {
    api.getPaymentTypes(token).then((value) => {
      setPaymentsTypes(value)
    })
    api.getPropertyTypes(token).then((value) => {
      setPropertyTypes(value)
    })
    api.getCountries(token).then((value) => {
      setCountries(value)
    })
  }, [])

  const [tenancyState, setTenancyState] = useState({
    activeStep: 0,
    startDate: '2023-01-01',
    endDate: '2023-01-01',
    deposit: 1500,
    monthlyPayment: 1500,
    asset: 'new',
    country: 'GB',
    propertyType: 'Apartment',
    addressSearch: '',
    address: {
      postcode: '',
      region: '',
      country: '',
      admin_district: '',
      admin_ward: '',
    } as Address,
    payments: [] as Payment[],
    measures: [] as Measure[],
  } as TenancyContractState)
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [activeStep, setActiveStep] = useState(0)
  const [newApartment, setNewApartment] = useState(true)
  const steps = [
    'Contract details',
    'Property info',
    'Initial payments',
    'Utility measures',
  ]

  const handleNext = () => {
    if (activeStep === steps.length - 1) handleAddPaymentClose()
    else
      setActiveStep(
        (prevActiveStep) => prevActiveStep + (activeStep === 0 && !newApartment ? 2 : 1),
      )
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleAssetChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    setNewApartment(value === 'new')
    console.log('newApartment=' + newApartment + ' value=' + value)
  }

  const showPropertyDetails = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  let res = new Set<Property>()
  api.getPropertyContracts().forEach((v) => {
    res.add(v.baseAsset as Property)
  })
  const assets = Array.from(res)

  const [measureValue, setMeasureValue] = useState(42.2 as number)
  const [measureType, setMeasureType] = useState(types[0] as string)
  const handleMeasureTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    setMeasureType(value)
  }
  const handleMeasureValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number
    setMeasureValue(value)
  }

  const [measures, setMeasures] = useState([] as Measure[])

  const [measureIndex, setMeasureIndex] = useState(1)
  const handleAddMeasure = () => {
    if (measureType === undefined || measureValue === undefined || measureValue === 0)
      return
    setMeasures((prevMeasures) => [
      ...prevMeasures,
      {
        type: measureType,
        value: measureValue,
        id: '' + measureIndex,
      } as Measure,
    ])
    setMeasureIndex(measureIndex + 1)
  }
  const deleteMeasure = (id: string) => {
    let idx = 0
    measures.forEach((m) => {
      if (m.id === id) idx = m.id as unknown as number
    })
    console.log(idx)
    let tmp = measures
    tmp.splice(idx, 1)
    console.log(JSON.stringify(tmp))
    setMeasures(tmp)
    setMeasureIndex(measureIndex)
  }

  const [paymentValue, setPaymentValue] = useState(42.2 as number)
  const [paymentDate, setPaymentDate] = useState('01/01/2021' as string)
  const [paymentType, setPaymentType] = useState(paymentTypes[0] as string)
  const [paymentIndex, setPaymentIndex] = useState(1)
  const [payments, setPayments] = useState([] as Payment[])
  const handlePaymentTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    setPaymentType(value)
  }
  const handlePaymentDateChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = event.target.value as string
    setPaymentDate(value)
  }
  const handlePaymentValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number
    setPaymentValue(value)
  }
  const handleAddPayment = () => {
    if (paymentType === undefined || paymentValue === undefined || paymentValue === 0)
      return
    setPayments((prevPayment) => [
      ...prevPayment,
      {
        type: 'FIXED',
        name: paymentType,
        amount: { amount: paymentValue, currency: 'GBP' },
        id: '' + paymentIndex,
      } as Payment,
    ])
    setPaymentIndex(paymentIndex + 1)
  }
  const deletePayment = (id: string) => {
    let idx = 0
    payments.forEach((m) => {
      if (m.id === id) idx = m.id as unknown as number
    })
    console.log(idx)
    let tmp = payments
    tmp.splice(idx, 1)
    console.log(JSON.stringify(tmp))
    setPayments(tmp)
    setPaymentIndex(paymentIndex)
  }

  const handleAddPaymentClose = () => {
    props.setOpenAddProperty(false)
    setActiveStep(0)
    //call API
    history.push(paths.properties)
  }

  const [search, setSearch] = useState('')
  const [address, setAddress] = useState({
    postcode: '',
    region: '',
    country: '',
    admin_district: '',
    admin_ward: '',
  } as Address)
  const [postcodes, setPostcodes] = useState([] as string[])

  useEffect(() => {
    if (postcodes.length === 1) {
      api.getAddress(postcodes[0]).then((address) => {
        setAddress(address)
      })
    }
    setAddress(address)
  }, [postcodes])

  const handleAddressChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    let postcode = event.target.value
    setSearch(postcode)
    api.autocompletePostCode(postcode).then((response) => {
      if (response === null) return
      setPostcodes(response.map((value) => value.replace(' ', '')))
    })
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={handleAddPaymentClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Add new tenancy agreement'}
      </DialogTitle>
      <DialogContent>
        <DesktopOnly>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </DesktopOnly>
        <MobileOnly>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </MobileOnly>
        <br />
        <Typography className={classes.instructions}>
          {(function () {
            switch (activeStep) {
              case 0:
                return (
                  <Grid container spacing={2}>
                    {/*<Grid item xs={12}>*/}
                    {/*    <FormControl variant="outlined" fullWidth required>*/}
                    {/*        <InputLabel htmlFor="side-native-simple">Side</InputLabel>*/}
                    {/*        <Select*/}
                    {/*            // defaultValue={property.side}*/}
                    {/*            defaultValue={'SELL'}*/}
                    {/*            // onChange={handleSideChange}*/}
                    {/*            label='Side'*/}
                    {/*            id='side-native-simple'*/}
                    {/*        >*/}
                    {/*            <MenuItem value="BUY">BUY</MenuItem>*/}
                    {/*            <MenuItem value="SELL">SELL</MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <FormControl variant="outlined" fullWidth required>*/}
                    {/*        <InputLabel htmlFor="contractType-native-simple">*/}
                    {/*            Contract type*/}
                    {/*        </InputLabel>*/}
                    {/*        <Select*/}
                    {/*            defaultValue="Rent"*/}
                    {/*            // onChange={handleContractTypeChange}*/}
                    {/*            label='Contract type'*/}
                    {/*            id='contractType-native-simple'*/}
                    {/*        >*/}
                    {/*            <MenuItem value="Rent">Rent</MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        type="date"
                        variant="outlined"
                        name="startDate"
                        id="startDate"
                        label="Start date"
                        value={tenancyState.startDate}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        type="date"
                        variant="outlined"
                        name="maturityDate"
                        id="maturityDate"
                        label="Maturity date"
                        value={tenancyState.endDate}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          {data.tenancyFields.deposit}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          startAdornment={
                            <InputAdornment position="start">£</InputAdornment>
                          }
                          label="Deposit"
                          value={tenancyState.deposit}
                          autoFocus
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          {data.tenancyFields.monthlyPayment}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          startAdornment={
                            <InputAdornment position="start">£</InputAdornment>
                          }
                          label="Monthly payment"
                          value={tenancyState.monthlyPayment}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" required fullWidth>
                        <InputLabel htmlFor="tenancy-asset-selector">
                          {data.tenancyFields.asset}
                        </InputLabel>
                        <Stack direction="row">
                          <Select
                            fullWidth
                            value={tenancyState.asset}
                            onChange={handleAssetChange}
                            label="Asset"
                            id="tenancy-asset-selector"
                          >
                            <MenuItem value="new">
                              <i>{data.tenancyFields.addProperty}</i>
                            </MenuItem>
                            {assets.map((a) => (
                              <MenuItem value={a.id}>{a.name}</MenuItem>
                            ))}
                          </Select>
                          {!newApartment && (
                            <Button onClick={showPropertyDetails}>
                              <VisibilityIcon />
                            </Button>
                          )}
                        </Stack>
                      </FormControl>
                    </Grid>
                  </Grid>
                )
              case 1:
                return (
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth required>
                          <InputLabel htmlFor="payment-type-selector">Country</InputLabel>
                          <Select
                            // onChange={handleCountryChange}
                            label="Country"
                            defaultValue={tenancyState.country}
                            id="payment-type-selector"
                          >
                            {countries.map((value: Country) => (
                              <MenuItem value={value.iso2}>
                                {value.iso2} - {value.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth required>
                          <InputLabel htmlFor="payment-type-selector">Type</InputLabel>
                          <Select
                            // onChange={handleTypeChange}
                            defaultValue={tenancyState.propertyType}
                            label="Type"
                            id="payment-type-selector"
                          >
                            {propertyTypes.map((type) => (
                              <MenuItem value={type}>{type}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={postcodes}
                          value={search}
                          onChange={(event: any, newValue: string | null) => {
                            setSearch(newValue === null ? '' : newValue)
                          }}
                          onInputChange={(event, newInputValue) => {
                            setSearch(newInputValue)
                          }}
                          // sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="outlined"
                              label={data.tenancyFields.addressSearch}
                              onChange={(event) => handleAddressChange(event)}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <TravelExploreIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/*<Grid item xs={12}>*/}
                      {/*    <TextField*/}
                      {/*        fullWidth*/}
                      {/*        variant="outlined"*/}
                      {/*        name="name"*/}
                      {/*        id="name"*/}
                      {/*        label={data.tenancyFields.addressSearch}*/}
                      {/*        autoFocus*/}
                      {/*        onChange={event => handleAddressChange(event)}*/}
                      {/*        InputProps={{*/}
                      {/*            startAdornment: (*/}
                      {/*                <InputAdornment position="start">*/}
                      {/*                    <TravelExploreIcon/>*/}
                      {/*                </InputAdornment>*/}
                      {/*            ),*/}
                      {/*        }}*/}
                      {/*    />*/}
                      {/*</Grid>*/}
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          value={address.region}
                          variant="outlined"
                          name="city"
                          id="city"
                          label="City"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          value={address.postcode}
                          variant="outlined"
                          name="zipCode"
                          id="zipCode"
                          label="Zip code"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          value={address.country}
                          variant="outlined"
                          name="region"
                          id="region"
                          label="Region"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          value={address.admin_ward}
                          variant="outlined"
                          name="addressLineOne"
                          id="addressLineOne"
                          label="Address line 1"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          value={address.admin_district}
                          variant="outlined"
                          name="addressLineTwo"
                          id="addressLineTwo"
                          label="Address line 2"
                        />
                      </Grid>
                    </Grid>
                  </form>
                )
              case 2:
                return (
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <List>
                          {payments.map((m) => (
                            <ListItem
                              id={'payment-' + m.id}
                              key={'payment-' + m.id}
                              value={m.amount.amount}
                            >
                              <ListItemText
                                primary={m.amount.amount + ' ' + m.amount.currency}
                                secondary={m.name + ' (' + m.date + ')'}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => deletePayment(m.id!)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          variant="outlined"
                          name="date"
                          id="date"
                          label="Date"
                          defaultValue={paymentDate}
                          onChange={(event) => handlePaymentDateChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth required>
                          <InputLabel htmlFor="payment-type-selector">Type</InputLabel>
                          <Select
                            onChange={handlePaymentTypeChange}
                            label="Type"
                            id="payment-type-selector"
                          >
                            {types.map((type) => (
                              <MenuItem value={type}>{type}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          defaultValue={paymentValue}
                          onChange={handlePaymentValueChange}
                          name="paymentValue"
                          id="paymentValue"
                          label="Value"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          // href={paths.addPayment.replace(":id", property.id)}
                          onClick={handleAddPayment}
                        >
                          {data.btn.addPayment}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )
              case 3:
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <List>
                        {measures.map((m) => (
                          <ListItem
                            id={'measure-' + m.id}
                            key={'measure-' + m.id}
                            value={m.value}
                          >
                            <ListItemText primary={m.value} secondary={m.type} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => deleteMeasure(m.id!)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel htmlFor="new-measure-type">Type</InputLabel>
                        <Select
                          onChange={handleMeasureTypeChange}
                          label="Type"
                          name="measureType"
                          defaultValue={measureType}
                          id="new-measure-type"
                        >
                          {types.map((type) => (
                            <MenuItem value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="value"
                        id="value"
                        label="Value"
                        defaultValue={measureValue}
                        onChange={handleMeasureValueChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddMeasure}
                      >
                        {data.btn.addMeasure}
                      </Button>
                    </Grid>
                  </Grid>
                )
              default:
                return data.msg.unknownStep
            }
          })()}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddPaymentClose} color="primary" variant="outlined">
          {data.btn.cancel}
        </Button>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}
        >
          {data.btn.back}
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {activeStep === steps.length - 1 ? data.btn.finish : data.btn.next}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
