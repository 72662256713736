import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import BackendApi from '../../services/backend'
import { useState } from 'react'
import { User } from '../../api'
import { Theme } from '@mui/material/styles/createTheme'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

export default function GeneralUserInfoPanel() {
  const classes = useStyles()
  const api = new BackendApi()

  const [user, setUser] = useState(api.getCurrentUser() as User)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    setUser((usr) => ({ ...usr, [name]: value }))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    api.updateDetails(user.firstName!, user.lastName!, user.username!)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} className={classes.centered}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="fname"
            name="firstName"
            variant="outlined"
            required
            fullWidth
            label="First Name"
            defaultValue={user.firstName ?? undefined}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Last Name"
            name="lastName"
            defaultValue={user.lastName ?? undefined}
            autoComplete="lname"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Email Address"
            name="email"
            disabled
            defaultValue={user.email ?? undefined}
            autoComplete="email"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Username"
            name="username"
            defaultValue={user.username ?? undefined}
            autoComplete="username"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Change details
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
