import React, { createContext, useContext, useMemo, useState } from 'react'
import { PriceLevel } from '../model/terminalModel'

interface OrderBookState {
  orderBook: PriceLevel[]
  setOrderBook: any
}

const orderBookInitialState = {
  orderBook: [],
  setOrderBook: undefined,
} as OrderBookState

const OrderBookStateContext = createContext(orderBookInitialState)

// @ts-ignore
export const OrderBookStateProvider = ({ children }) => {
  const [orderBook, setOrderBook] = useState(orderBookInitialState.orderBook)
  const orderBookContextValue = useMemo(
    () =>
      ({
        orderBook,
        setOrderBook,
      } as OrderBookState),
    [orderBook],
  )

  return (
    <OrderBookStateContext.Provider value={orderBookContextValue}>
      {children}
    </OrderBookStateContext.Provider>
  )
}

export const useOrderBookState = () => useContext(OrderBookStateContext)
