import { Autocomplete, Avatar, darken, lighten, Select, TextField } from '@mui/material'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import BackendApi, { Asset } from '../services/backend'
import useToken from '../services/useToken'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))
const GroupItems = styled('ul')({
  padding: 0,
})

export default function AssetSelector({ ...props }) {
  const { token } = useToken()
  const api = new BackendApi()
  const [assets, setAssets] = useState([] as Asset[])

  useEffect(() => {
    api
      .getAssets(token)
      .then((value) => {
        setAssets(value)
      })
      .catch((reason) => {
        console.log(reason)
      })
  }, [])

  return assets.length === 0 ? (
    <Select fullWidth {...props} disabled />
  ) : (
    <>
      <Autocomplete
        autoHighlight
        autoSelect
        autoComplete
        getOptionLabel={(option) =>
          option === undefined ? 'null' : option.name + ' (' + option.ticker + ')'
        }
        options={assets.sort((a, b) => {
          const t = b.type.localeCompare(a.type)
          return t === 0 ? -b.name.localeCompare(a.name) : t
        })}
        groupBy={(option) => option.type}
        renderGroup={(params) => (
          <li>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            key={option.ticker}
            {...props}
          >
            <img
              loading="lazy"
              width="24"
              src={`/flags/${option.ticker?.substring(0, 2).toLowerCase()}.webp`}
              srcSet={`https://flagcdn.com/w40/${option.ticker.toLowerCase()}.png 2x`}
              alt=""
            />
            {option && `${option.name} (${option.ticker})`}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        value={assets.find((e) => e.ticker === props.value)}
        onChange={(event: SyntheticEvent, value: Asset | null) => {
          props.onChange(value !== null && value !== undefined ? value.ticker : undefined)
        }}
      />
      {/*<FormControl fullWidth>*/}
      {/*    <InputLabel id="asset-lbl">{data.wallets.field.currency}</InputLabel>*/}
      {/*    <Select labelId="asset-lbl"*/}
      {/*        {...props}>*/}
      {/*        <ListSubheader>{data.selector.assetType.fiat}</ListSubheader>*/}
      {/*        {assets.map((value) => (*/}
      {/*            value.state === 'ACTIVE' && value.type === 'FIAT' &&*/}
      {/*            <MenuItem value={value.ticker}>*/}
      {/*                <Stack direction="row" spacing={1}*/}
      {/*                       alignItems="center">*/}
      {/*                    <Avatar alt={value.ticker}*/}
      {/*                            sx={{width: 24, height: 24}}*/}
      {/*                            src={`/flags/${value.ticker?.substring(0, 2).toLowerCase()}.webp`}/>*/}
      {/*                    <Typography>*/}
      {/*                        {value.name} ({value.ticker})*/}
      {/*                    </Typography>*/}
      {/*                </Stack>*/}
      {/*            </MenuItem>*/}
      {/*        ))}*/}
      {/*        <ListSubheader>{data.selector.assetType.commodity}</ListSubheader>*/}
      {/*        {assets.map((value) => (*/}
      {/*            value.state === 'ACTIVE' && value.type === 'COMMODITY' &&*/}
      {/*            <MenuItem value={value.ticker}>{value.name} ({value.ticker})</MenuItem>*/}
      {/*        ))}*/}
      {/*        <ListSubheader>{data.selector.assetType.crypto}</ListSubheader>*/}
      {/*        {assets.map((value) => (*/}
      {/*            value.state === 'ACTIVE' && value.type === 'CRYPTO' &&*/}
      {/*            <MenuItem value={value.ticker}>{value.name} ({value.ticker})</MenuItem>*/}
      {/*        ))}*/}
      {/*        <ListSubheader>{data.selector.assetType.other}</ListSubheader>*/}
      {/*        {assets.map((value) => (*/}
      {/*            value.state === 'ACTIVE'*/}
      {/*            && (value.type !== 'FIAT' && value.type !== 'COMMODITY' && value.type !== 'CRYPTO')*/}
      {/*            && <MenuItem value={value.ticker}>{value.name} ({value.ticker})</MenuItem>*/}
      {/*        ))}*/}
      {/*    </Select>*/}
      {/*</FormControl>*/}
    </>
  )
}
