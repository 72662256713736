import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import paths from '../../data/paths.json'
import BackendApi from '../../services/backend'
import { Measure } from '../../model/modelTypes'
import { useState } from 'react'
import data from '../../data/locale/en/data.json'
import Stack from '@mui/material/Stack'

type Params = {
  propertyId: string
  open: boolean
  setOpenAddMeasure: (open: boolean) => void
}
export default function AddMeasureDialog(props: Params) {
  const propertyId = props.propertyId
  const openAddMeasure = props.open
  const setOpenAddMeasure = props.setOpenAddMeasure

  const [measure, setMeasure] = useState({} as Measure)
  const api = new BackendApi()
  const [types] = useState(api.getMeasureTypes() as string[])

  const history = useHistory()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    setMeasure((m) => ({ ...m, [name]: value }))
  }

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string
    const newTariff: string = '' + api.getTariff(propertyId, value, measure.date)
    console.log('Tariff for ' + value + ' = ' + newTariff)
    setMeasure((m) => ({ ...m, tariff: newTariff }))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    api.addMeasure(propertyId, [measure])
    handleAddMeasureClose()
  }

  const handleAddMeasureClose = () => {
    setOpenAddMeasure(false)
    history.push(paths.propertyMeasures.replace(':id', propertyId))
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openAddMeasure}
      onClose={handleAddMeasureClose}
      aria-labelledby="responsive-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="responsive-dialog-title">{data.btn.addMeasure}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>{data.dialog.addMeasureDescription}</DialogContentText>
            <TextField
              autoFocus
              required
              fullWidth
              type="date"
              variant="outlined"
              name="date"
              onChange={handleChange}
              defaultValue={measure.date}
              label="Date"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="asset-native-simple">Type</InputLabel>
              <Select
                fullWidth
                required
                onChange={handleTypeChange}
                label="Type"
                name="type"
                defaultValue={measure.type}
                id="asset-native-simple"
              >
                {types.map((type) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              required
              fullWidth
              disabled
              type="number"
              variant="outlined"
              name="tariff"
              onChange={handleChange}
              label="Tariff"
              defaultValue={measure.tariff}
            />
            <TextField
              required
              fullWidth
              type="number"
              variant="outlined"
              name="value"
              onChange={handleChange}
              defaultValue={measure.value}
              label="Value"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            type="reset"
            onClick={handleAddMeasureClose}
          >
            {data.btn.cancel}
          </Button>
          <Button color="primary" variant="contained" type="submit" autoFocus>
            {data.btn.addMeasure}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
