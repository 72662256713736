import { Avatar, Button, CardContent, Grid } from '@mui/material'
import { miniApps } from '../components/MiniApps'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import LaunchIcon from '@mui/icons-material/Launch'
import { Link } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SecurityIcon from '@mui/icons-material/Security'

import paths from '../data/paths.json'
import { deepOrange, green } from '@mui/material/colors'

export default function MiniApps() {
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      // alignItems="stretch"
      alignItems="center"
    >
      <Grid item sm style={{ width: '100%' }}>
        <Card sx={{ bgcolor: deepOrange[700] }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: deepOrange[300] }}>
                <AccountCircleIcon />
              </Avatar>
            }
            title={'Settings'}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {'User details info'}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              component={Link}
              to={paths.userDetails.general}
              variant="outlined"
              size="small"
              endIcon={<LaunchIcon />}
            >
              Open app
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item sm style={{ width: '100%' }}>
        <Card sx={{ bgcolor: green[700] }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: green[500] }}>
                <SecurityIcon />
              </Avatar>
            }
            title={'Security'}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {'Login/Password details'}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              component={Link}
              to={paths.userDetails.security}
              variant="outlined"
              size="small"
              endIcon={<LaunchIcon />}
            >
              Open app
            </Button>
          </CardActions>
        </Card>
      </Grid>
      {miniApps.map(
        (value: any) =>
          value.enabled && (
            <Grid item sm style={{ width: '100%', height: '100%' }}>
              <Card>
                <CardHeader avatar={<Avatar>{value.icon}</Avatar>} title={value.name} />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {value.description}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Button
                    component={Link}
                    to={value.path}
                    variant="outlined"
                    size="small"
                    endIcon={<LaunchIcon />}
                  >
                    Open app
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ),
      )}
    </Grid>
  )
}
