import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import HomeIcon from '@mui/icons-material/Home'
import RestoreIcon from '@mui/icons-material/Restore'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Link } from 'react-router-dom'
import paths from '../../data/paths.json'

export default function AddSubscription() {
  return (
    <Container component="form" maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              <HomeIcon />
              Home
            </Link>
            <Link color="inherit" to={paths.subscriptions}>
              <RestoreIcon />
              Subscriptions
            </Link>
            <Typography color="textPrimary">New</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="name"
            name="name"
            label="Name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="period"
            name="period"
            label="Period"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="amount"
            name="amount"
            label="Amount"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="currency"
            label="Currency"
            name="currency"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="startDate"
            name="startDate"
            label="Start date"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            href={paths.public.confirm}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
