import { useState } from 'react'
import { Notification } from '../api'
import BackendApi from '../services/backend'

export default function useNotifications() {
  const api = new BackendApi()
  const alerts = api.getAlerts()
  const [notifications, setNotifications] = useState(alerts as Notification[])

  // FIXME
  // const { token } = useToken()
  // const [notifications, setNotifications] = useState([] as Notification[])
  // useEffect(() => {
  //     const api = new SystemApi()

  //     const options = {
  //         headers: {
  //             'Authorization': "Bearer " + token
  //         }
  //     }
  //     api.getNotifications(options)
  //         .then((rsp: any) => setNotifications(rsp.data as Notification[]))
  //         .catch((error: any) => {
  //             console.log(error)
  //         })
  // }, [token])

  const notificationsCount = () => {
    return notifications.length
  }

  const clearAll = () => () => {
    setNotifications([])
  }

  return {
    notifications,
    notificationsCount,
    clearAll,
  }
}
