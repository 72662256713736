import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useOrderBookState } from '../../services/useOrderBookState'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

function NewOrderComponent() {
  const [instrument, setInstrument] = useState<string>('ETH-USD')
  const [orderType, setOrderType] = useState<number>(0)

  const [lastPrice, setLastPrice] = useState<number>(0)
  const [stop, setStop] = useState<number>(0)
  const [limit, setLimit] = useState<number>(0)
  const [stopLimit, setStopLimit] = useState<boolean>(false)
  const { orderBook } = useOrderBookState()

  useEffect(() => {
    const mid =
      orderBook.length === 0
        ? 0
        : ((
            (orderBook[orderBook.length / 2].priceLevel +
              orderBook[orderBook.length / 2 - 1].priceLevel) /
            2
          ).toFixed(2) as unknown as number)
    setLastPrice(mid)
    if (orderType !== 1 && !stopLimit) {
      setLimit(mid)
    }
    if (orderType <= 1) {
      setStop((mid * 0.95).toFixed(2) as unknown as number)
    }
  }, [stopLimit, orderType, orderBook, lastPrice])

  const getPrice = () => {
    return orderType === 1 || (orderType === 2 && stopLimit) ? limit : lastPrice
  }

  const textType = () => {
    let type
    switch (orderType) {
      case 0:
        type = 'market'
        break
      case 1:
        type = 'limit'
        break
      default:
        type = stopLimit ? 'stop-limit' : 'stop'
    }
    return type
  }

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setOrderType(newValue)
  }
  const [side, setSide] = useState('buy')

  const handleStopLimit = () => {
    setStopLimit(!stopLimit)
  }

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <AddCircleOutlineIcon />
          </Avatar>
        }
        // action={
        //     <IconButton aria-label="settings">
        //         <MoreVertIcon/>
        //     </IconButton>
        // }
        title={
          <>
            New order
            {/*<InfoIcon color={"disabled"} style={{marginBlock: 'auto'}}/>*/}
          </>
        }
        // subheader={instrument}
      />
      <CardContent>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              color="primary"
              value={side}
              exclusive
              onChange={(event, newSide) => setSide(newSide || side)}
              aria-label="Order side"
              fullWidth={true}
            >
              <ToggleButton color={'success'} value="buy">
                <TrendingUpIcon /> Buy
              </ToggleButton>
              <ToggleButton color={'error'} value="sell">
                Sell <TrendingDownIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <Tabs value={orderType} onChange={handleChange} centered>
              <Tab label="Market" />
              <Tab label="Limit" />
              <Tab label="Stop" />
              {/*<IconButton><ArrowDropDownIcon/></IconButton>*/}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="amount"
              label="Amount"
              defaultValue={1}
              onChange={() => {
                // setProduct(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={orderType === 2 ? 6 : 12}>
            <TextField
              fullWidth
              id="limit"
              label={
                ((orderType === 2 && !stopLimit) || orderType === 0
                  ? 'Market'
                  : 'Limit') + ' Price'
              }
              disabled={orderType === 0 || (orderType === 2 && !stopLimit)}
              defaultValue={limit}
              value={orderType === 0 ? lastPrice : limit}
              onChange={() => {
                // setProduct(event.target.value)
              }}
              InputProps={
                orderType !== 2
                  ? {}
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle stop-limit"
                            onClick={handleStopLimit}
                            edge="end"
                          >
                            {stopLimit ? (
                              <CheckCircleOutlineIcon />
                            ) : (
                              <Tooltip title="Enable limit price for stop order">
                                <RadioButtonUncheckedIcon />
                              </Tooltip>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
              }
            />
          </Grid>
          {orderType === 2 && (
            <Grid item xs={6}>
              <TextField
                id="stop"
                label="Stop Price"
                // disabled={orderType !== 2}
                defaultValue={stop}
                value={stop}
                onChange={() => {
                  // setProduct(event.target.value)
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Tooltip
              title={`Place ${side} ${textType()} order
                                @ ${getPrice()}`}
            >
              <Button
                fullWidth={true}
                color={side === 'buy' ? 'success' : 'error'}
                variant="outlined"
              >
                {side} {instrument} @ {getPrice()}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default NewOrderComponent
