"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fintecy App
 * This is api to connect to server of fintecy app.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anton@fintecy.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletsApi = exports.WalletsApiFactory = exports.WalletsApiFp = exports.WalletsApiAxiosParamCreator = exports.UsersApi = exports.UsersApiFactory = exports.UsersApiFp = exports.UsersApiAxiosParamCreator = exports.TransactionsApi = exports.TransactionsApiFactory = exports.TransactionsApiFp = exports.TransactionsApiAxiosParamCreator = exports.SystemApi = exports.SystemApiFactory = exports.SystemApiFp = exports.SystemApiAxiosParamCreator = exports.PropertiesApi = exports.PropertiesApiFactory = exports.PropertiesApiFp = exports.PropertiesApiAxiosParamCreator = exports.AuthApi = exports.AuthApiFactory = exports.AuthApiFp = exports.AuthApiAxiosParamCreator = exports.AccountsApi = exports.AccountsApiFactory = exports.AccountsApiFp = exports.AccountsApiAxiosParamCreator = exports.UserStateEnum = exports.AccountStateEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
exports.AccountStateEnum = {
    Active: 'ACTIVE',
    Closed: 'CLOSED',
};
exports.UserStateEnum = {
    Registered: 'REGISTERED',
    Active: 'ACTIVE',
    Deleted: 'DELETED',
};
/**
 * AccountsApi - axios parameter creator
 * @export
 */
var AccountsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Add new account for specific user
         * @summary Add new user account
         * @param {Account} account create new account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccount: function (account, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'account' is not null or undefined
                            (0, common_1.assertParamExists)('addAccount', 'account', account);
                            localVarPath = "/accounts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(account, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary delete account
         * @param {string} accountId Account to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: function (accountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteAccount', 'accountId', accountId);
                            localVarPath = "/accounts/{accountId}".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get user account details
         * @summary Get account details
         * @param {string} accountId Account for editing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDetails: function (accountId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('getAccountDetails', 'accountId', accountId);
                            localVarPath = "/accounts/{accountId}".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of accounts
         * @summary Get all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/accounts";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update an existing account
         * @param {string} accountId Account for editing.
         * @param {Account} account Account object that needs to be added to the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: function (accountId, account, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'accountId' is not null or undefined
                            (0, common_1.assertParamExists)('updateAccount', 'accountId', accountId);
                            // verify required parameter 'account' is not null or undefined
                            (0, common_1.assertParamExists)('updateAccount', 'account', account);
                            localVarPath = "/accounts/{accountId}".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(account, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.AccountsApiAxiosParamCreator = AccountsApiAxiosParamCreator;
/**
 * AccountsApi - functional programming interface
 * @export
 */
var AccountsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AccountsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Add new account for specific user
         * @summary Add new user account
         * @param {Account} account create new account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccount: function (account, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addAccount(account, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary delete account
         * @param {string} accountId Account to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: function (accountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteAccount(accountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get user account details
         * @summary Get account details
         * @param {string} accountId Account for editing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDetails: function (accountId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccountDetails(accountId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of accounts
         * @summary Get all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAccounts(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update an existing account
         * @param {string} accountId Account for editing.
         * @param {Account} account Account object that needs to be added to the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: function (accountId, account, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAccount(accountId, account, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AccountsApiFp = AccountsApiFp;
/**
 * AccountsApi - factory interface
 * @export
 */
var AccountsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AccountsApiFp)(configuration);
    return {
        /**
         * Add new account for specific user
         * @summary Add new user account
         * @param {Account} account create new account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccount: function (account, options) {
            return localVarFp
                .addAccount(account, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary delete account
         * @param {string} accountId Account to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: function (accountId, options) {
            return localVarFp
                .deleteAccount(accountId, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get user account details
         * @summary Get account details
         * @param {string} accountId Account for editing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDetails: function (accountId, options) {
            return localVarFp
                .getAccountDetails(accountId, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of accounts
         * @summary Get all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: function (options) {
            return localVarFp.getAccounts(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update an existing account
         * @param {string} accountId Account for editing.
         * @param {Account} account Account object that needs to be added to the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: function (accountId, account, options) {
            return localVarFp
                .updateAccount(accountId, account, options)
                .then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AccountsApiFactory = AccountsApiFactory;
/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
var AccountsApi = /** @class */ (function (_super) {
    __extends(AccountsApi, _super);
    function AccountsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add new account for specific user
     * @summary Add new user account
     * @param {Account} account create new account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.addAccount = function (account, options) {
        var _this = this;
        return (0, exports.AccountsApiFp)(this.configuration)
            .addAccount(account, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary delete account
     * @param {string} accountId Account to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.deleteAccount = function (accountId, options) {
        var _this = this;
        return (0, exports.AccountsApiFp)(this.configuration)
            .deleteAccount(accountId, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get user account details
     * @summary Get account details
     * @param {string} accountId Account for editing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getAccountDetails = function (accountId, options) {
        var _this = this;
        return (0, exports.AccountsApiFp)(this.configuration)
            .getAccountDetails(accountId, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of accounts
     * @summary Get all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.getAccounts = function (options) {
        var _this = this;
        return (0, exports.AccountsApiFp)(this.configuration)
            .getAccounts(options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update an existing account
     * @param {string} accountId Account for editing.
     * @param {Account} account Account object that needs to be added to the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    AccountsApi.prototype.updateAccount = function (accountId, account, options) {
        var _this = this;
        return (0, exports.AccountsApiFp)(this.configuration)
            .updateAccount(accountId, account, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AccountsApi;
}(base_1.BaseAPI));
exports.AccountsApi = AccountsApi;
/**
 * AuthApi - axios parameter creator
 * @export
 */
var AuthApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Logs user into the system with user credentials to get jwt token in respose
         * @summary Logs user into the system
         * @param {Credentials} credentials Login with user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin: function (credentials, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'credentials' is not null or undefined
                    (0, common_1.assertParamExists)('authLogin', 'credentials', credentials);
                    localVarPath = "/auth/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication basicAuth required
                    // http basic authentication required
                    (0, common_1.setBasicAuthToObject)(localVarRequestOptions, configuration);
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(credentials, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Replace refresh token on jwt tokens
         * @summary Refresh jwt tokens
         * @param {RefreshToken} refreshToken Refresh token from auth/login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefresh: function (refreshToken, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'refreshToken' is not null or undefined
                            (0, common_1.assertParamExists)('authRefresh', 'refreshToken', refreshToken);
                            localVarPath = "/auth/refresh";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(refreshToken, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Confirmation of ownership of email address
         * @summary Confirmation of email
         * @param {string} token Confirmation token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: function (token, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'token' is not null or undefined
                    (0, common_1.assertParamExists)('confirmEmail', 'token', token);
                    localVarPath = "/auth/confirm";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication basicAuth required
                    // http basic authentication required
                    (0, common_1.setBasicAuthToObject)(localVarRequestOptions, configuration);
                    if (token !== undefined) {
                        localVarQueryParameter['token'] = token;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.AuthApiAxiosParamCreator = AuthApiAxiosParamCreator;
/**
 * AuthApi - functional programming interface
 * @export
 */
var AuthApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AuthApiAxiosParamCreator)(configuration);
    return {
        /**
         * Logs user into the system with user credentials to get jwt token in respose
         * @summary Logs user into the system
         * @param {Credentials} credentials Login with user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin: function (credentials, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.authLogin(credentials, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Replace refresh token on jwt tokens
         * @summary Refresh jwt tokens
         * @param {RefreshToken} refreshToken Refresh token from auth/login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefresh: function (refreshToken, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.authRefresh(refreshToken, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Confirmation of ownership of email address
         * @summary Confirmation of email
         * @param {string} token Confirmation token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: function (token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmEmail(token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AuthApiFp = AuthApiFp;
/**
 * AuthApi - factory interface
 * @export
 */
var AuthApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AuthApiFp)(configuration);
    return {
        /**
         * Logs user into the system with user credentials to get jwt token in respose
         * @summary Logs user into the system
         * @param {Credentials} credentials Login with user credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin: function (credentials, options) {
            return localVarFp
                .authLogin(credentials, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Replace refresh token on jwt tokens
         * @summary Refresh jwt tokens
         * @param {RefreshToken} refreshToken Refresh token from auth/login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefresh: function (refreshToken, options) {
            return localVarFp
                .authRefresh(refreshToken, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Confirmation of ownership of email address
         * @summary Confirmation of email
         * @param {string} token Confirmation token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: function (token, options) {
            return localVarFp
                .confirmEmail(token, options)
                .then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AuthApiFactory = AuthApiFactory;
/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
var AuthApi = /** @class */ (function (_super) {
    __extends(AuthApi, _super);
    function AuthApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Logs user into the system with user credentials to get jwt token in respose
     * @summary Logs user into the system
     * @param {Credentials} credentials Login with user credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    AuthApi.prototype.authLogin = function (credentials, options) {
        var _this = this;
        return (0, exports.AuthApiFp)(this.configuration)
            .authLogin(credentials, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Replace refresh token on jwt tokens
     * @summary Refresh jwt tokens
     * @param {RefreshToken} refreshToken Refresh token from auth/login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    AuthApi.prototype.authRefresh = function (refreshToken, options) {
        var _this = this;
        return (0, exports.AuthApiFp)(this.configuration)
            .authRefresh(refreshToken, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Confirmation of ownership of email address
     * @summary Confirmation of email
     * @param {string} token Confirmation token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    AuthApi.prototype.confirmEmail = function (token, options) {
        var _this = this;
        return (0, exports.AuthApiFp)(this.configuration)
            .confirmEmail(token, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AuthApi;
}(base_1.BaseAPI));
exports.AuthApi = AuthApi;
/**
 * PropertiesApi - axios parameter creator
 * @export
 */
var PropertiesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * List of available property types
         * @summary Property types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTypes: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/property-types";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PropertiesApiAxiosParamCreator = PropertiesApiAxiosParamCreator;
/**
 * PropertiesApi - functional programming interface
 * @export
 */
var PropertiesApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PropertiesApiAxiosParamCreator)(configuration);
    return {
        /**
         * List of available property types
         * @summary Property types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTypes: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPropertyTypes(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PropertiesApiFp = PropertiesApiFp;
/**
 * PropertiesApi - factory interface
 * @export
 */
var PropertiesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PropertiesApiFp)(configuration);
    return {
        /**
         * List of available property types
         * @summary Property types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTypes: function (options) {
            return localVarFp
                .getPropertyTypes(options)
                .then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PropertiesApiFactory = PropertiesApiFactory;
/**
 * PropertiesApi - object-oriented interface
 * @export
 * @class PropertiesApi
 * @extends {BaseAPI}
 */
var PropertiesApi = /** @class */ (function (_super) {
    __extends(PropertiesApi, _super);
    function PropertiesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * List of available property types
     * @summary Property types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    PropertiesApi.prototype.getPropertyTypes = function (options) {
        var _this = this;
        return (0, exports.PropertiesApiFp)(this.configuration)
            .getPropertyTypes(options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PropertiesApi;
}(base_1.BaseAPI));
exports.PropertiesApi = PropertiesApi;
/**
 * SystemApi - axios parameter creator
 * @export
 */
var SystemApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Users notifications
         * @summary Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/notifications";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Exchange rates for currencies
         * @summary FX rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRates: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/rates";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Users subscriptions
         * @summary Subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/subscriptions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SystemApiAxiosParamCreator = SystemApiAxiosParamCreator;
/**
 * SystemApi - functional programming interface
 * @export
 */
var SystemApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SystemApiAxiosParamCreator)(configuration);
    return {
        /**
         * Users notifications
         * @summary Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getNotifications(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Exchange rates for currencies
         * @summary FX rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRates: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRates(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Users subscriptions
         * @summary Subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSubscriptions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SystemApiFp = SystemApiFp;
/**
 * SystemApi - factory interface
 * @export
 */
var SystemApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SystemApiFp)(configuration);
    return {
        /**
         * Users notifications
         * @summary Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: function (options) {
            return localVarFp
                .getNotifications(options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Exchange rates for currencies
         * @summary FX rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRates: function (options) {
            return localVarFp.getRates(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Users subscriptions
         * @summary Subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: function (options) {
            return localVarFp
                .getSubscriptions(options)
                .then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SystemApiFactory = SystemApiFactory;
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
var SystemApi = /** @class */ (function (_super) {
    __extends(SystemApi, _super);
    function SystemApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Users notifications
     * @summary Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    SystemApi.prototype.getNotifications = function (options) {
        var _this = this;
        return (0, exports.SystemApiFp)(this.configuration)
            .getNotifications(options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Exchange rates for currencies
     * @summary FX rates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    SystemApi.prototype.getRates = function (options) {
        var _this = this;
        return (0, exports.SystemApiFp)(this.configuration)
            .getRates(options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Users subscriptions
     * @summary Subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    SystemApi.prototype.getSubscriptions = function (options) {
        var _this = this;
        return (0, exports.SystemApiFp)(this.configuration)
            .getSubscriptions(options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SystemApi;
}(base_1.BaseAPI));
exports.SystemApi = SystemApi;
/**
 * TransactionsApi - axios parameter creator
 * @export
 */
var TransactionsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Add transaction to blotter
         * @summary Add transaction
         * @param {string} transactionId transaction uuid
         * @param {Transaction} transaction order placed for purchasing the pet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTransaction: function (transactionId, transaction, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'transactionId' is not null or undefined
                            (0, common_1.assertParamExists)('addTransaction', 'transactionId', transactionId);
                            // verify required parameter 'transaction' is not null or undefined
                            (0, common_1.assertParamExists)('addTransaction', 'transaction', transaction);
                            localVarPath = "/transactions/{transactionId}".replace("{".concat('transactionId', "}"), encodeURIComponent(String(transactionId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(transaction, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete specified transaction
         * @summary Delete transaction by ID
         * @param {string} transactionId transaction uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction: function (transactionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'transactionId' is not null or undefined
                    (0, common_1.assertParamExists)('deleteTransaction', 'transactionId', transactionId);
                    localVarPath = "/transactions/{transactionId}".replace("{".concat('transactionId', "}"), encodeURIComponent(String(transactionId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    // authentication basicAuth required
                    // http basic authentication required
                    (0, common_1.setBasicAuthToObject)(localVarRequestOptions, configuration);
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Find transaction details
         * @summary Find transaction details
         * @param {string} transactionId transaction uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails: function (transactionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'transactionId' is not null or undefined
                            (0, common_1.assertParamExists)('getTransactionDetails', 'transactionId', transactionId);
                            localVarPath = "/transactions/{transactionId}".replace("{".concat('transactionId', "}"), encodeURIComponent(String(transactionId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns a transaction blotter
         * @summary Transaction blotter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/transactions";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TransactionsApiAxiosParamCreator = TransactionsApiAxiosParamCreator;
/**
 * TransactionsApi - functional programming interface
 * @export
 */
var TransactionsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TransactionsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Add transaction to blotter
         * @summary Add transaction
         * @param {string} transactionId transaction uuid
         * @param {Transaction} transaction order placed for purchasing the pet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTransaction: function (transactionId, transaction, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addTransaction(transactionId, transaction, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete specified transaction
         * @summary Delete transaction by ID
         * @param {string} transactionId transaction uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction: function (transactionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteTransaction(transactionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Find transaction details
         * @summary Find transaction details
         * @param {string} transactionId transaction uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails: function (transactionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTransactionDetails(transactionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns a transaction blotter
         * @summary Transaction blotter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getTransactions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TransactionsApiFp = TransactionsApiFp;
/**
 * TransactionsApi - factory interface
 * @export
 */
var TransactionsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TransactionsApiFp)(configuration);
    return {
        /**
         * Add transaction to blotter
         * @summary Add transaction
         * @param {string} transactionId transaction uuid
         * @param {Transaction} transaction order placed for purchasing the pet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTransaction: function (transactionId, transaction, options) {
            return localVarFp
                .addTransaction(transactionId, transaction, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete specified transaction
         * @summary Delete transaction by ID
         * @param {string} transactionId transaction uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction: function (transactionId, options) {
            return localVarFp
                .deleteTransaction(transactionId, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Find transaction details
         * @summary Find transaction details
         * @param {string} transactionId transaction uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails: function (transactionId, options) {
            return localVarFp
                .getTransactionDetails(transactionId, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns a transaction blotter
         * @summary Transaction blotter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: function (options) {
            return localVarFp
                .getTransactions(options)
                .then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TransactionsApiFactory = TransactionsApiFactory;
/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
var TransactionsApi = /** @class */ (function (_super) {
    __extends(TransactionsApi, _super);
    function TransactionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Add transaction to blotter
     * @summary Add transaction
     * @param {string} transactionId transaction uuid
     * @param {Transaction} transaction order placed for purchasing the pet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.addTransaction = function (transactionId, transaction, options) {
        var _this = this;
        return (0, exports.TransactionsApiFp)(this.configuration)
            .addTransaction(transactionId, transaction, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete specified transaction
     * @summary Delete transaction by ID
     * @param {string} transactionId transaction uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.deleteTransaction = function (transactionId, options) {
        var _this = this;
        return (0, exports.TransactionsApiFp)(this.configuration)
            .deleteTransaction(transactionId, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Find transaction details
     * @summary Find transaction details
     * @param {string} transactionId transaction uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.getTransactionDetails = function (transactionId, options) {
        var _this = this;
        return (0, exports.TransactionsApiFp)(this.configuration)
            .getTransactionDetails(transactionId, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns a transaction blotter
     * @summary Transaction blotter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    TransactionsApi.prototype.getTransactions = function (options) {
        var _this = this;
        return (0, exports.TransactionsApiFp)(this.configuration)
            .getTransactions(options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TransactionsApi;
}(base_1.BaseAPI));
exports.TransactionsApi = TransactionsApi;
/**
 * UsersApi - axios parameter creator
 * @export
 */
var UsersApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Register new user with minimum parameters
         * @summary Register new user
         * @param {Registration} registration Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (registration, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'registration' is not null or undefined
                            (0, common_1.assertParamExists)('createUser', 'registration', registration);
                            localVarPath = "/users";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(registration, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} username The name that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (username, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'username' is not null or undefined
                            (0, common_1.assertParamExists)('deleteUser', 'username', username);
                            localVarPath = "/users/{username}".replace("{".concat('username', "}"), encodeURIComponent(String(username)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Fetch user details from server
         * @summary Get user details
         * @param {string} username The username that needs to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (username, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'username' is not null or undefined
                            (0, common_1.assertParamExists)('getUser', 'username', username);
                            localVarPath = "/users/{username}".replace("{".concat('username', "}"), encodeURIComponent(String(username)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.UsersApiAxiosParamCreator = UsersApiAxiosParamCreator;
/**
 * UsersApi - functional programming interface
 * @export
 */
var UsersApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.UsersApiAxiosParamCreator)(configuration);
    return {
        /**
         * Register new user with minimum parameters
         * @summary Register new user
         * @param {Registration} registration Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (registration, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createUser(registration, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} username The name that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (username, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteUser(username, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Fetch user details from server
         * @summary Get user details
         * @param {string} username The username that needs to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (username, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUser(username, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.UsersApiFp = UsersApiFp;
/**
 * UsersApi - factory interface
 * @export
 */
var UsersApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.UsersApiFp)(configuration);
    return {
        /**
         * Register new user with minimum parameters
         * @summary Register new user
         * @param {Registration} registration Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: function (registration, options) {
            return localVarFp
                .createUser(registration, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} username The name that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: function (username, options) {
            return localVarFp
                .deleteUser(username, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Fetch user details from server
         * @summary Get user details
         * @param {string} username The username that needs to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: function (username, options) {
            return localVarFp
                .getUser(username, options)
                .then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.UsersApiFactory = UsersApiFactory;
/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
var UsersApi = /** @class */ (function (_super) {
    __extends(UsersApi, _super);
    function UsersApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Register new user with minimum parameters
     * @summary Register new user
     * @param {Registration} registration Created user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.createUser = function (registration, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration)
            .createUser(registration, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * This can only be done by the logged in user.
     * @summary Delete user
     * @param {string} username The name that needs to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.deleteUser = function (username, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration)
            .deleteUser(username, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Fetch user details from server
     * @summary Get user details
     * @param {string} username The username that needs to be fetched.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    UsersApi.prototype.getUser = function (username, options) {
        var _this = this;
        return (0, exports.UsersApiFp)(this.configuration)
            .getUser(username, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UsersApi;
}(base_1.BaseAPI));
exports.UsersApi = UsersApi;
/**
 * WalletsApi - axios parameter creator
 * @export
 */
var WalletsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Add a new wallet to the account
         * @param {Wallet} wallet create new account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWallet: function (wallet, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'wallet' is not null or undefined
                            (0, common_1.assertParamExists)('addWallet', 'wallet', wallet);
                            localVarPath = "/wallets";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(wallet, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a wallet
         * @param {string} walletId Wallet id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWallet: function (walletId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'walletId' is not null or undefined
                            (0, common_1.assertParamExists)('deleteWallet', 'walletId', walletId);
                            localVarPath = "/wallets/{walletId}".replace("{".concat('walletId', "}"), encodeURIComponent(String(walletId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Returns a single wallet details
         * @summary Wallet details
         * @param {string} walletId ID of wallet to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet: function (walletId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'walletId' is not null or undefined
                            (0, common_1.assertParamExists)('getWallet', 'walletId', walletId);
                            localVarPath = "/wallets/{walletId}".replace("{".concat('walletId', "}"), encodeURIComponent(String(walletId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get list of user accounts
         * @summary Get all wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/wallets";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update an existing wallet details
         * @param {string} walletId ID of wallet to return
         * @param {Wallet} wallet Update wallet details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWallet: function (walletId, wallet, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'walletId' is not null or undefined
                            (0, common_1.assertParamExists)('updateWallet', 'walletId', walletId);
                            // verify required parameter 'wallet' is not null or undefined
                            (0, common_1.assertParamExists)('updateWallet', 'wallet', wallet);
                            localVarPath = "/wallets/{walletId}".replace("{".concat('walletId', "}"), encodeURIComponent(String(walletId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(wallet, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.WalletsApiAxiosParamCreator = WalletsApiAxiosParamCreator;
/**
 * WalletsApi - functional programming interface
 * @export
 */
var WalletsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.WalletsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Add a new wallet to the account
         * @param {Wallet} wallet create new account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWallet: function (wallet, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.addWallet(wallet, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a wallet
         * @param {string} walletId Wallet id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWallet: function (walletId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteWallet(walletId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns a single wallet details
         * @summary Wallet details
         * @param {string} walletId ID of wallet to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet: function (walletId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getWallet(walletId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get list of user accounts
         * @summary Get all wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getWallets(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update an existing wallet details
         * @param {string} walletId ID of wallet to return
         * @param {Wallet} wallet Update wallet details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWallet: function (walletId, wallet, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateWallet(walletId, wallet, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.WalletsApiFp = WalletsApiFp;
/**
 * WalletsApi - factory interface
 * @export
 */
var WalletsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.WalletsApiFp)(configuration);
    return {
        /**
         *
         * @summary Add a new wallet to the account
         * @param {Wallet} wallet create new account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWallet: function (wallet, options) {
            return localVarFp
                .addWallet(wallet, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a wallet
         * @param {string} walletId Wallet id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWallet: function (walletId, options) {
            return localVarFp
                .deleteWallet(walletId, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns a single wallet details
         * @summary Wallet details
         * @param {string} walletId ID of wallet to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet: function (walletId, options) {
            return localVarFp
                .getWallet(walletId, options)
                .then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get list of user accounts
         * @summary Get all wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets: function (options) {
            return localVarFp.getWallets(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update an existing wallet details
         * @param {string} walletId ID of wallet to return
         * @param {Wallet} wallet Update wallet details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWallet: function (walletId, wallet, options) {
            return localVarFp
                .updateWallet(walletId, wallet, options)
                .then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.WalletsApiFactory = WalletsApiFactory;
/**
 * WalletsApi - object-oriented interface
 * @export
 * @class WalletsApi
 * @extends {BaseAPI}
 */
var WalletsApi = /** @class */ (function (_super) {
    __extends(WalletsApi, _super);
    function WalletsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Add a new wallet to the account
     * @param {Wallet} wallet create new account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    WalletsApi.prototype.addWallet = function (wallet, options) {
        var _this = this;
        return (0, exports.WalletsApiFp)(this.configuration)
            .addWallet(wallet, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a wallet
     * @param {string} walletId Wallet id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    WalletsApi.prototype.deleteWallet = function (walletId, options) {
        var _this = this;
        return (0, exports.WalletsApiFp)(this.configuration)
            .deleteWallet(walletId, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns a single wallet details
     * @summary Wallet details
     * @param {string} walletId ID of wallet to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    WalletsApi.prototype.getWallet = function (walletId, options) {
        var _this = this;
        return (0, exports.WalletsApiFp)(this.configuration)
            .getWallet(walletId, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get list of user accounts
     * @summary Get all wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    WalletsApi.prototype.getWallets = function (options) {
        var _this = this;
        return (0, exports.WalletsApiFp)(this.configuration)
            .getWallets(options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update an existing wallet details
     * @param {string} walletId ID of wallet to return
     * @param {Wallet} wallet Update wallet details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletsApi
     */
    WalletsApi.prototype.updateWallet = function (walletId, wallet, options) {
        var _this = this;
        return (0, exports.WalletsApiFp)(this.configuration)
            .updateWallet(walletId, wallet, options)
            .then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return WalletsApi;
}(base_1.BaseAPI));
exports.WalletsApi = WalletsApi;
