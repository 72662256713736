import React from 'react'
import Popover from '@mui/material/Popover'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationList from './NotificationList'
import useNotifications from '../services/useNotifications'
import { useBetween } from 'use-between'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import Box from '@mui/material/Box'
import features from '../data/features.json'

const useStyles = makeStyles((theme: Theme) => ({
  notification: {
    maxWidth: '350px',
  },
}))

export default function NotificationTooltip() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const notificationsOpen = Boolean(anchorEl)
  const id = notificationsOpen ? 'simple-popover' : undefined

  const useSharedState = () => useBetween(useNotifications)
  const { notificationsCount } = useSharedState()

  return features.user.notifications ? (
    <>
      <IconButton color="inherit" aria-describedby={id} onClick={handleClick}>
        <Badge badgeContent={notificationsCount()} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={notificationsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.notification}>
          <NotificationList />
        </Box>
      </Popover>
    </>
  ) : (
    <></>
  )
}
