import * as React from 'react'
import { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import { green, red } from '@mui/material/colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Integration } from '../../model/modelTypes'
import { Stack, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import data from '../../data/locale/en/data.json'
import useToken from '../../services/useToken'
import BackendApi from '../../services/backend'

// @ts-ignore
export default function IntegrationCard({ value }) {
  const [integration, setIntegration] = useState<Integration>(value)
  const [expanded, setExpanded] = useState<boolean>(false)
  const [version, setVersion] = useState<number>(0)

  const { token } = useToken()
  const [api] = useState<BackendApi>(new BackendApi())

  const lastUpdated = () => {
    const updatedDate = integration.updatedAt.substring(0, 10)
    const now = new Date().toISOString()
    if (now.substring(0, 10) === updatedDate)
      return 'today at ' + integration.updatedAt.substring(11, 19)

    let yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
    if (yesterday.substring(0, 10) === updatedDate)
      return 'yesterday at ' + integration.updatedAt.substring(11, 19)

    return 'at ' + updatedDate
  }

  function handleChange() {
    setIntegration((i) => ({ ...i, enabled: !i.enabled }))
    setVersion((v) => v + 1)
  }

  function updateCredential(name: string, value: string) {
    setIntegration((i) => {
      let creds = i.credentials.map((cred) => {
        return cred.name === name ? { ...cred, name: name, value: value } : cred
      })
      return { ...i, credentials: creds }
    })
    setVersion((v) => v + 1)
  }

  useEffect(() => {
    if (version === 0) return
    api.updateIntegrations(integration.id, integration, token).then(() => {
      console.log('Updated integration: ' + JSON.stringify(integration))
    })
  }, [version])

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: integration.enabled ? green[500] : red[500] }}
            aria-label="recipe"
          >
            {integration.type.substring(0, 2)}
          </Avatar>
        }
        title={integration.type}
        subheader={'Last updated ' + lastUpdated()}
      />
      <CardContent id={integration.id}>
        <Stack direction="column" spacing={2}>
          <Button
            variant="outlined"
            startIcon={integration.enabled ? <TaskAltIcon /> : <HighlightOffIcon />}
            color={integration.enabled ? 'success' : 'warning'}
            onClick={() => handleChange()}
          >
            {integration.enabled ? 'Enabled' : 'Disabled'}
          </Button>
          <Button
            variant="outlined"
            startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            {data.integrations.field.credentials}
          </Button>
        </Stack>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack direction="column" spacing={2}>
            {integration.credentials.map((cred) => (
              <TextField
                fullWidth
                id={'cred-' + integration.type + '-' + cred.name}
                key={'cred-' + integration.type + '-' + cred.name}
                label={cred.name}
                name={cred.name}
                value={cred.value}
                type={'text'}
                variant="outlined"
                onChange={(event) =>
                  updateCredential(cred.name, event.target.value as unknown as string)
                }
              />
            ))}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  )
}
