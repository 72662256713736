import { useState } from 'react'

export default function useDrawer() {
  const [drawerOpen, setDrawerOpen] = useState(false as boolean)

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }
  return {
    drawerOpen,
    setDrawerOpen,
    handleDrawerOpen,
    handleDrawerClose,
  }
}
