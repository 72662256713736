import React, { FC } from 'react'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Tooltip } from '@mui/material'

interface InfoProps {
  title: string
}

const Title: FC<InfoProps> = ({ title }) => {
  return (
    <Tooltip title={title} arrow>
      <sup>
        <HelpOutlineIcon fontSize={'small'} style={{ color: '#9e9e9e80' }} />
      </sup>
    </Tooltip>
  )
}

export default Title
