import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  TextField,
} from '@mui/material'
import StarRateIcon from '@mui/icons-material/StarRate'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import OrderBookService from '../../services/OrderBookService'
import SearchIcon from '@mui/icons-material/Search'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/Inbox'
import useProductState from '../../services/useProductState'

function AssetStateComponent() {
  const { productState } = useProductState()
  const [products, setProducts] = useState([productState.product])
  const titles = {
    component: 'Market',
  }

  function updateProducts(value: string) {
    OrderBookService.getProducts().then((response) => {
      setProducts(response.data.filter((v: string) => v.indexOf(value) >= 0))
    })
  }

  useEffect(() => {
    updateProducts('')
  }, [productState])

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <FormatListBulletedIcon />
          </Avatar>
        }
        // action={
        //     <IconButton aria-label="settings">
        //         <MoreVertIcon/>
        //     </IconButton>
        // }
        title={titles.component}
        // subheader={product}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="market-search"
              label="Market search"
              type="search"
              fullWidth={true}
              onChange={(event) => {
                updateProducts(event.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <List
              sx={{
                // width: '100%',
                // maxWidth: 360,
                // bgcolor: 'background.paper',
                // position: 'relative',
                overflow: 'auto',
                maxHeight: 280,
                // '& ul': { padding: 0 },
              }}
            >
              <ListItem
                disablePadding
                key={productState.product}
                secondaryAction={
                  <IconButton edge="end" aria-label="selectedIcon">
                    <StarRateIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <InboxIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={productState.product}
                  secondary={productState.lastPrice}
                />
              </ListItem>
              {products.map(
                (row) =>
                  row !== productState.product && (
                    <ListItem disablePadding key={row}>
                      <ListItemAvatar>
                        <Avatar>
                          <InboxIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={row}
                        secondary={
                          row === productState.product
                            ? productState.lastPrice
                            : '1234.56'
                        }
                      />
                    </ListItem>
                  ),
              )}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AssetStateComponent
