import React, { useState } from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SettingsIcon from '@mui/icons-material/Settings'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import NightsStayIcon from '@mui/icons-material/NightsStay'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import {
  Checkbox, Chip,
  ListItemButton,
  ListItemSecondaryAction,
  Paper,
  useTheme,
} from '@mui/material'
import { Link } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import features from '../data/features.json'
import paths from '../data/paths.json'
import Avatar from "@mui/material/Avatar";
import useUserInfo from "../services/useUserInfo";

// @ts-ignore
export default function UserDetailsMenu({ deleteToken, toggleDarkTheme }) {
  const theme = useTheme()
  const userInfo = useUserInfo()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const userMenuOpen = Boolean(anchorEl)
  const id = userMenuOpen ? 'simple-popover' : undefined

  const [checked, setChecked] = useState(theme.palette.mode === 'dark')

  const handleToggle = () => () => {
    setChecked(!checked)
    toggleDarkTheme()
  }

  const handleLogout = async (e: any) => {
    e.preventDefault()
    deleteToken()
  }
  return (
    <>
      {/*<IconButton color="inherit" aria-describedby={id} onClick={handleClick}>*/}
      {/*  <AccountCircleIcon />*/}
      {/*</IconButton>*/}
      <Chip
          avatar={<Avatar alt="Account"><AccountCircleIcon /></Avatar>}
          label={userInfo.fullName}
          aria-describedby={id}
          variant="outlined"
          onClick={handleClick}
          clickable
          component="button"
      />
      <Popover
        id={id}
        open={userMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper>
          <List>
            <ListItem>
              <ListItemIcon>
                <AccountCircleIcon color="primary" fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={userInfo.fullName}
                secondary={
                  features.user.billing && (
                    <React.Fragment>
                      Your tier: <Link to={paths.userDetails.billing}>Premium</Link>
                    </React.Fragment>
                  )
                }
              />
            </ListItem>
            <Divider />
            {features.darkMode && (
              <ListItemButton key={0} role={undefined} dense onClick={handleToggle()}>
                <ListItemIcon>
                  {checked ? <NightsStayIcon /> : <WbSunnyIcon />}
                </ListItemIcon>
                <ListItemText id={'checkbox-list-label-0'} primary={'Dark mode'} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="start"
                    checked={checked}
                    onChange={handleToggle()}
                    color="primary"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-0' }}
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}
            <ListItem
              button
              dense
              color="inherit"
              component={Link}
              to={paths.userDetails.general}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={'Settings'} />
            </ListItem>
            <Divider />
            <ListItemButton dense onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
          </List>
        </Paper>
      </Popover>
    </>
  )
}
