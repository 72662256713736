import { AuthApi, AuthToken, Credentials, User } from '../api'
import axios, { AxiosResponse } from 'axios'
import properties from '../_mocks/properties.json'
import integrations from '../_mocks/integrations.json'
import alerts from '../_mocks/alerts.json'
import user from '../_mocks/User.data.json'
import {
  Integration,
  Measure,
  Payment,
  Property,
  PropertyContract,
  Side,
  UUID,
} from '../model/modelTypes'

export interface DetailsChangedResponse {
  changed: boolean
  msg: string
}

export interface ClientOrder {
  side: Side
  state: string
  type: string
  time: string
  price: number
  amount: number
  remaining: number
}

function createData(
  side: Side,
  state: string,
  type: string,
  time: string,
  price: number,
  amount: number,
  remaining: number,
) {
  return { side, state, type, time, price, amount, remaining } as ClientOrder
}

const rows = [
  createData(Side.BUY, 'ACTIVE', 'LIMIT', '21:43:38', 19541.02, 0.004, 0.004),
  createData(Side.BUY, 'ACTIVE', 'LIMIT', '21:43:37', 19541.23, 0.12614, 0.12614),
  createData(Side.BUY, 'ACTIVE', 'LIMIT', '21:43:36', 19541.01, 0.00112, 0.00112),
  createData(Side.SELL, 'FILLED', 'MARKET', '21:43:35', 19541.01, 0.0126, 0),
  createData(Side.BUY, 'ACTIVE', 'LIMIT', '21:43:34', 19541.02, 0.00266, 0.00266),
] as ClientOrder[]

export interface NotificationSetting {
  notification: string
  category: string
  enabled: boolean
}

export interface Address {
  postcode: string
  country: string
  region: string
  admin_district: string
  admin_ward: string
}

// export const BASE_PATH = 'https://api.fintecy.org/v1'.replace(/\/+$/, '')
export const BASE_PATH = "http://127.0.0.1:8080/v1".replace(/\/+$/, "");

export interface Wallet {
  organisationId?: string
  accountId: string
  balance: number
  code: string
  name: string
  currency: string
  id: any
  type: string
}

export interface Asset {
  state: string
  ticker: string
  numCode: string
  fractionDigits: string
  name: string
  symbol: string
  type: string
  // flags: string,
  assetId: string
}

export interface Country {
  iso2: string
  name: string
}

export interface Account {
  id: string
  ownerId: string
  organizationId: string
  name: string
  code: string
}

export interface Organisation {
  id: string
  code: string
  name: string
  country: string
  type: string
  parentId?: string
}

export interface Transaction {
  id: any
  amount: number
  name: string
  tradeDate: string
  settlementDate: string
  fromWalletId: string
  toWalletId: string
  type: string
  ref: string
  asset: string
}

export default class BackendApi {
  public getOrders(): ClientOrder[] {
    return rows
  }

  public getNotificationSettings(): NotificationSetting[] {
    return [
      {
        category: 'product',
        notification: 'email',
        enabled: true,
      },
      {
        category: 'product',
        notification: 'webPush',
        enabled: false,
      },
      {
        category: 'security',
        notification: 'email',
        enabled: false,
      },
      {
        category: 'security',
        notification: 'webPush',
        enabled: false,
      },
    ]
  }

  public getCurrentUser(): User {
    return user
  }

  public async autocompletePostCode(value: string): Promise<string[]> {
    try {
      return value === undefined || value === null || value.length === 0
        ? new Promise(() => [])
        : axios
            .get('https://api.postcodes.io/postcodes/' + value + '/autocomplete')
            .then((response) => {
              return response.data === undefined || response.data === null
                ? []
                : response.data.result
            })
            .finally(() => [])
    } catch (e) {
      return new Promise(() => [])
    }
  }

  public async getAddress(postcode: string): Promise<Address> {
    const EMPTY_ADDRESS = {
      postcode: postcode,
      region: '',
      country: '',
      admin_district: '',
      admin_ward: '',
    } as Address
    try {
      return axios
        .get('https://api.postcodes.io/postcodes/' + postcode)
        .then((response) => {
          return response.data === undefined || response.data === null
            ? EMPTY_ADDRESS
            : ({
                postcode: response.data.result.postcode,
                region: response.data.result.region,
                country: response.data.result.country,
                admin_district: response.data.result.admin_district,
                admin_ward: response.data.result.admin_ward,
              } as Address)
        })
        .finally(() => EMPTY_ADDRESS)
    } catch (e) {
      return new Promise(() => EMPTY_ADDRESS)
    }
  }

  public getProperties(token: string): Promise<Property[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.get(BASE_PATH + '/properties', options).then((value) => value.data)
  }

  public getPropertyContracts(): PropertyContract[] {
    return properties as unknown as PropertyContract[]
  }

  public getPropertyContractDetails(
    id: string,
    token: string,
  ): Promise<PropertyContract> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/property-contracts/' + id, options)
      .then((value) => value.data)
  }

  public getAlerts() {
    return alerts
  }

  public getNotifications(token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.get(BASE_PATH + '/notifications', options)
  }

  public getTariff(contractId: UUID, type: string, date: string): number {
    //FIXME
    switch (type) {
      case 'Rent':
        return 1
      case 'Electricity':
        return 3.12
      case 'Gas':
        return 1.56
      case 'Water':
        return 6.32
      default:
        return 1.23
    }
  }

  public getMeasures(contractId: UUID): Measure[] {
    return []
  }

  public getMeasureTypes(): string[] {
    let measures = ['Rent', 'Electricity', 'Gas', 'Water']
    // axios.get(BASE_PATH + "/utility-types")
    //     .then((response) => {
    //         return response.data === undefined || response.data === null ? [] : response.data;
    //     })
    //     .finally(() => ["Rent", "Electricity", "Gas", "Water"])
    return measures
  }

  public getPaymentTypes(token: string): Promise<string[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/payment-types', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .finally(() => ['Rent', 'Electricity', 'Gas', 'Water'])
  }

  public getPropertyTypes(token: string): Promise<string[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/property-types', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .finally(() => [
        'Apartment',
        'Detached House',
        'Semi-Detached House',
        'Terrace House',
        'End Terrace House',
        'Land',
        'Other',
      ])
  }

  public getCountries(token: string): Promise<Country[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/countries', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .catch(() => [])
  }

  public getAssets(token: string): Promise<Asset[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/assets', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .catch(() => [])
  }

  public getAccountTypes(): string[] {
    return ['PERSONAL', 'BUSINESS']
  }

  public getWalletTypes(token: string): Promise<string[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/walletTypes', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .catch(() => [
        'DEFAULT',
        'CASH',
        'BROKER',
        'BANK',
        'CREDIT',
        'MORTGAGE',
        'SAVING',
        'GIA',
        'ISA',
        'PENSION',
      ])
  }

  public getTxTypes(token: string): Promise<string[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/transaction-types', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .catch(() => ['EXPENSE', 'INCOME', 'EXCHANGE', 'TRANSFER', 'FEE'])
  }

  public getOrgTypes(): string[] {
    return [
      'DEFAULT', //CASH
      'BANK',
      'CREDIT_UNION',
      'BROKER',
      'GIA_BROKER',
      'ISA_BROKER',
      'PENSION_FUND',
    ]
  }

  public addMeasure(contractId: UUID, measures: Measure[]): Measure[] {
    //FIXME
    const json = JSON.stringify(measures)
    console.log('Submit new measure ' + json)
    return this.getMeasures(contractId)
  }

  public getPayments(contractId: UUID): Payment[] {
    return []
  }

  public addPayments(contractId: UUID, payments: Payment[]): Payment[] {
    //FIXME
    return this.getPayments(contractId)
  }

  public updateDetails(
    firstName: string,
    lastName: string,
    username: string,
  ): DetailsChangedResponse {
    //FIXME
    console.log(
      'FirstName=' + firstName + ' lastName=' + lastName + ' userName=' + username,
    )
    return {
      changed: true,
      msg: 'user details changed',
    }
  }

  public changePassword(
    oldPassword: string,
    newPassword: string,
    repeatPassword: string,
  ): DetailsChangedResponse {
    if (repeatPassword !== newPassword)
      return {
        changed: false,
        msg: 'Passwords are not equal',
      }
    else if (oldPassword === newPassword)
      return {
        changed: false,
        msg: 'New passwords should be different to old password',
      }
    else
      return {
        changed: true,
        msg: 'Password successfully changed',
      }
  }

  public async loginUser(username: string, password: string) {
    if (username === 'demo@fintecy.org' && password === 'Demo123!')
      return {
        accessToken: 'Demo123!',
        refreshToken: 'Demo123!',
      }

    const authService = new AuthApi()
    const credentials: Credentials = {
      username: username,
      password: password,
    }
    const options = {
      headers: {
        // 'Authorization': "Bearer " + "token123"
        Authorization: 'Basic ' + btoa(username + ':' + password),
      },
      timeout: 3000,
    }
    return (
      authService
        .authLogin(credentials, options)
        // @ts-ignore
        .then((token: AxiosResponse<AuthToken, Credentials>) => token.data)
    )
    // .catch((reason: any) => console.log(reason))
  }

  public getWalletDetails(id: string, token: string): Promise<Wallet> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.get(BASE_PATH + '/wallets/' + id, options).then((value) => value.data)
  }

  public getAccountDetails(id: string, token: string): Promise<Account> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.get(BASE_PATH + '/accounts/' + id, options).then((value) => value.data)
  }

  public getOrganisationDetails(id: string, token: string): Promise<Organisation> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/organizations/' + id, options)
      .then((value) => value.data)
  }

  public async deleteWallet(walletId: string, token: string): Promise<String> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.delete(BASE_PATH + '/wallets/' + walletId, options)
  }

  public async deleteOrganization(orgId: string, token: string): Promise<String> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.delete(BASE_PATH + '/organizations/' + orgId, options)
  }

  createWallet(wallet: Wallet, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.post(BASE_PATH + '/wallets', wallet, options)
  }

  updateWallet(wallet: Wallet, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.put(BASE_PATH + '/wallets/' + wallet.id, wallet, options)
  }

  createAccount(account: Account, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.post(BASE_PATH + '/accounts', account, options)
  }

  updateAccount(account: Account, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.put(BASE_PATH + '/accounts/' + account.id, account, options)
  }

  updateOrganisation(organisation: Organisation, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.put(
      BASE_PATH + '/organizations/' + organisation.id,
      organisation,
      options,
    )
  }

  createOrganisation(organisation: Organisation, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.post(BASE_PATH + '/organizations', organisation, options)
  }

  public getTxDetails(id: string, token: string): Promise<any> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/transactions/' + id, options)
      .then((value) => value.data)
  }

  public createTx(tx: Transaction, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.post(BASE_PATH + '/transactions', tx, options)
  }

  public updateTx(tx: Transaction, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.put(BASE_PATH + '/transactions/' + tx.id, tx, options)
  }

  public getAccounts(token: string): Promise<Account[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/accounts', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .finally(() => [])
  }

  public getAccountsByOrg(orgId: string, token: string): Promise<Account[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/accounts?organisationId=' + orgId, options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .finally(() => [])
  }

  public getWallets(token: string): Promise<Wallet[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/wallets', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .finally(() => [])
  }

  public getOrganisations(token: string): Promise<Organisation[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/organizations', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .finally(() => [])
  }

  public updateIntegrations(
    id: string,
    integration: Integration,
    token: string,
  ): Promise<Integration> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .put(BASE_PATH + '/integrations/' + id, integration, options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
  }

  public updateCredentials(
    id: string,
    name: string,
    value: string,
    token: string,
  ): Promise<Integration> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    const body = {
      [name]: value,
    }
    return axios
      .put(BASE_PATH + '/integrations/' + id, body, options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .finally(() => integrations[0])
  }

  public getIntegrations(token: string): Promise<Integration[]> {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios
      .get(BASE_PATH + '/integrations', options)
      .then((response) => {
        return response.data === undefined || response.data === null ? [] : response.data
      })
      .finally(() => [])
  }

  updateWalletBalance(walletId: string, updatedBalance: number, token: string) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      timeout: 3000,
    }
    return axios.put(
      BASE_PATH + '/wallets/' + walletId + '?balance=' + updatedBalance,
      '{}',
      options,
    )
  }
}
