import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import {
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@mui/material'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import useNotifications from '../services/useNotifications'
import { useBetween } from 'use-between'
import paths from '../data/paths.json'

const useStyles = makeStyles((theme: Theme) => ({
  centered: {
    textAlign: 'center',
  },
  flexCentered: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function NotificationList() {
  const classes = useStyles()
  const useSharedState = () => useBetween(useNotifications)
  const { notifications, notificationsCount, clearAll } = useSharedState()

  return (
    <Paper>
      <List>
        <ListItem>
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText primary={'All notifications'} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={clearAll()}>
              <DoneAllIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {notificationsCount() === 0 ? (
          <>
            <Divider />
            <ListItem>
              <ListItemText primary={'No alerts...'} className={classes.centered} />
            </ListItem>
          </>
        ) : (
          notifications.map((alert) => (
            <>
              <Divider />
              <Link color="inherit" href={alert.url}>
                <ListItemButton alignItems={'center'}>
                  <ListItemText primary={alert.message} secondary={alert.date} />
                </ListItemButton>
              </Link>
            </>
          ))
        )}
        <Divider />
        <Button className={classes.flexCentered} href={paths.notifications}>
          View All
        </Button>
      </List>
    </Paper>
  )
}
