import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'

import { ExchangeRate, SystemApi } from '../../api'
import useToken from '../../services/useToken'
import CardContent from '@mui/material/CardContent'
import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  Button,
  Card,
  Chip,
  Stack,
  Typography,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import Info from '../../components/Info'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'

function QuotesPage() {
  const { token } = useToken()

  const mockRates = Array.from({ length: 34 }, (_, i) => i + 1)
  const getRates = () => {
    return JSON.parse((localStorage.getItem('rates') || '[]') as string) as ExchangeRate[]
  }

  const saveRates = (rates: ExchangeRate[]) => {
    localStorage.setItem('rates', JSON.stringify(rates))
    setExchangeRates(rates as ExchangeRate[])
  }
  const [exchangeRates, setExchangeRates] = useState(getRates())

  useEffect(() => {
    const api = new SystemApi()

    const options = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    api
      .getRates(options)
      .then((rsp: any) => saveRates(rsp.data as ExchangeRate[]))
      .catch((error: any) => {
        console.log(error)
      })
  }, [token])

  return (
    <Grid container spacing={3}>
      {exchangeRates.length === 0 ? (
        <>
          <Grid item xs={12}>
            <Alert severity="info">
              <AlertTitle>Loading FX rates</AlertTitle>
              Fetching new rates from data source - <strong>CBR API</strong>!
            </Alert>
          </Grid>
          {mockRates.map((v) => (
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Card>
                <CardContent>
                  <Stack direction="column" justifyContent="space-around" spacing={2}>
                    <Stack direction="row" justifyContent="space-around" spacing={2}>
                      <Skeleton variant="circular">
                        <Avatar />
                      </Skeleton>
                      <Typography gutterBottom variant="h6" component="div">
                        <Skeleton variant="text">___/___</Skeleton>
                      </Typography>
                    </Stack>
                    <Chip
                      label={<Skeleton variant="text">00.0000</Skeleton>}
                      color="error"
                      variant="outlined"
                    />
                    <Chip
                      label={<Skeleton variant="text">00.0000</Skeleton>}
                      color="success"
                      variant="outlined"
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </>
      ) : (
        exchangeRates.map((row) => (
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Card>
              <CardContent>
                <Stack direction="column" justifyContent="space-around" spacing={2}>
                  <Stack direction="row" justifyContent="space-around" spacing={2}>
                    <Badge
                      style={{ zIndex: 0 }}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      badgeContent={
                        <Avatar
                          alt={row.counter}
                          src={`/flags/${row.counter
                            ?.substring(0, 2)
                            .toLowerCase()}.webp`}
                        />
                      }
                    >
                      <Avatar
                        alt={row.base}
                        src={`/flags/${row.base?.substring(0, 2).toLowerCase()}.webp`}
                      />
                    </Badge>
                    <Typography gutterBottom variant="h6" component="div">
                      {row.counter + '/' + row.base}
                      <Info title={'Source ' + row.origin || 'unknown'} />
                    </Typography>
                  </Stack>
                  <Chip
                    icon={
                      Math.round((row.bid || 0) * 10000) % 2 === 0 ? (
                        <TrendingUpIcon />
                      ) : (
                        <TrendingDownIcon />
                      )
                    }
                    label={Math.round((row.bid || 0) * 10000) / 10000}
                    color={
                      Math.round((row.bid || 0) * 10000) % 2 === 0 ? 'success' : 'error'
                    }
                    variant="outlined"
                  />
                  <Button
                    href={`https://www.tradingview.com/symbols/${row.counter}${row.base}`}
                    variant="outlined"
                    startIcon={<QueryStatsIcon />}
                  >
                    {' '}
                    Chart
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
      <Grid item xs={12}>
        <Typography gutterBottom variant="body2">
          <sup>*</sup> Last rate from central bank of Russia used as source for this data.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default QuotesPage
