import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(1),
  },
  plan: {
    margin: theme.spacing(2),
  },
}))

export default function BillingPanel() {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h4" component="h2">
        Change plan
      </Typography>
      You can upgrade and downgrade whenever you want
      <Divider light variant="middle" className={classes.divider} />
      <Box display="flex">
        <Box flexGrow={1} className={classes.plan}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h3">
                $0.00 / mo
              </Typography>
              Basic
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={1} className={classes.plan}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h3">
                $4.99 / mo
              </Typography>
              Standard
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={1} className={classes.plan}>
          <Card style={{ backgroundColor: '#64b5f6' }}>
            <CardContent>
              <Typography variant="h5" component="h3">
                $9.99 / mo
              </Typography>
              Premium
            </CardContent>
          </Card>
        </Box>
      </Box>
      {/* <Grid container spacing={3}>
                    <Grid item className={classes.plan}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h3">
                                    $0.00 / mo
                                </Typography>
                                Basic
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className={classes.plan}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h3">
                                    $4.99 / mo
                                </Typography>

                                Standard
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className={classes.plan}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="h3">
                                    $9.99 / mo
                                </Typography>

                                Premium
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> */}
    </>
  )
}
