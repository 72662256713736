import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import HomeIcon from '@mui/icons-material/Home'
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Link, useHistory, useParams } from 'react-router-dom'
import paths from '../../../data/paths.json'
import React, { useEffect, useState } from 'react'
import useToken from '../../../services/useToken'
import BackendApi, { Transaction, Wallet } from '../../../services/backend'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import data from '../../../data/locale/en/data.json'
import { SelectChangeEvent } from '@mui/material/Select'
import AssetSelector from '../../../components/AssetSelector'

export default function AddTransaction() {
  // @ts-ignore
  let { id } = useParams()
  const history = useHistory()
  const { token } = useToken()

  const api = new BackendApi()

  const [txTypes, setTxTypes] = useState([] as string[])
  const [wallets, setWallets] = useState([] as Wallet[])

  const [tx, setTx] = useState({
    amount: 0,
    type: 'EXPENSE',
    fromWalletId: '',
    toWalletId: '',
    tradeDate: '2023-01-01T01:00',
    settlementDate: '2023-01-01',
    ref: '',
    asset: 'GBP',
    id,
  } as Transaction)
  useEffect(() => {
    api.getWallets(token).then((value) => {
      setWallets(value)
    })
    api.getTxTypes(token).then((value) => {
      setTxTypes(value)
    })
    if (id !== undefined) {
      api.getTxDetails(id, token).then((value) => {
        console.log('fetched wallet info: ' + JSON.stringify(value))
        setTx({
          ...tx,
          settlementDate: value.settlementDate.substring(0, 10),
          tradeDate: value.tradeDate.substring(0, 16),
          amount: value.amount,
          asset: value.asset,
          type: value.type,
          ref: value.ref,
          fromWalletId: value.fromWalletId,
          toWalletId: value.toWalletId,
          id: value.id,
        })
      })
    }
  }, [])

  function handleFieldChange(name: string, value: string) {
    setTx({
      ...tx,
      [name]: value,
    })
    console.log('Update tx: ' + JSON.stringify(tx))
  }

  function handleInputChange(
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<String>,
  ) {
    handleFieldChange(event.target.name, event.target.value as string)
  }

  function createTx(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (id !== undefined) {
      console.log('tx updated: ' + tx)
      api.updateTx(tx, token).then((r) => {
        history.push(paths.wallets)
      })
    } else {
      console.log('tx created: ' + tx)
      api.createTx(tx, token).then((r) => {
        if (r.status === 201) history.push(paths.transactions)
      })
    }
  }

  return (
    <Container component="form" onSubmit={(event) => createTx(event)} maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              <HomeIcon />
              Home
            </Link>
            <Link color="inherit" to={paths.transactions}>
              <SwapHorizontalCircleIcon />
              Transactions
            </Link>
            <Typography color="textPrimary">
              {tx.id !== undefined ? tx.ref : 'New'}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="type-lbl">{data.transactions.field.type}</InputLabel>
            <Select
              value={tx.type}
              id="type"
              labelId="type-lbl"
              name="type"
              label={data.transactions.field.type}
              onChange={(event) => handleInputChange(event)}
            >
              {txTypes.map((value) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {(tx.type === 'EXCHANGE' ||
          tx.type === 'TRANSFER' ||
          tx.type === 'EXPENSE' ||
          tx.type === 'FEE') && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="fromWalletId-lbl">
                {data.transactions.field.fromWalletId}
              </InputLabel>
              <Select
                value={tx.fromWalletId}
                id="fromWalletId"
                labelId="fromWalletId-lbl"
                name="fromWalletId"
                label={data.transactions.field.fromWalletId}
                onChange={(event) => handleInputChange(event)}
              >
                {wallets.map((value) => (
                  <MenuItem value={value.id}>
                    {value.name} ({value.code})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {tx.type !== 'EXPENSE' && tx.type !== 'FEE' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="toWalletId-lbl">
                {data.transactions.field.toWalletId}
              </InputLabel>
              <Select
                value={tx.toWalletId}
                id="toWalletId"
                labelId="toWalletId-lbl"
                name="toWalletId"
                label={data.transactions.field.toWalletId}
                onChange={(event) => handleInputChange(event)}
              >
                {wallets.map((value) => (
                  <MenuItem value={value.id}>
                    {value.name} ({value.code})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={tx.ref}
            onChange={(event) => handleInputChange(event)}
            id="ref"
            name="ref"
            label={data.transactions.field.ref}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={tx.tradeDate}
            onChange={(event) => handleInputChange(event)}
            id="tradeDate"
            name="tradeDate"
            type={'datetime-local'}
            label={data.transactions.field.tradeDate}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={tx.settlementDate}
            onChange={(event) => handleInputChange(event)}
            id="settlementDate"
            name="settlementDate"
            type={'date'}
            label={data.transactions.field.settlementDate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={tx.amount}
            onChange={(event) => handleInputChange(event)}
            type={'number'}
            id="amount"
            name="amount"
            label={data.transactions.field.amount}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AssetSelector
            value={tx.asset}
            onChange={(newValue: string) => handleFieldChange('asset', newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color={tx.id !== undefined ? 'secondary' : 'primary'}
          >
            {tx.id !== undefined ? data.btn.update : data.btn.create}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
