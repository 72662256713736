/* tslint:disable */
/* eslint-disable */
/**
 * Fintecy App
 * This is api to connect to server of fintecy app.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anton@fintecy.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base'

/**
 *
 * @export
 * @interface Account
 */
export interface Account {
  /**
   *
   * @type {string}
   * @memberof Account
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Account
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof Account
   */
  organizationId?: string
  /**
   *
   * @type {string}
   * @memberof Account
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof Account
   */
  name: string
  /**
   * account state
   * @type {string}
   * @memberof Account
   */
  state?: AccountStateEnum
}

export const AccountStateEnum = {
  Active: 'ACTIVE',
  Closed: 'CLOSED',
} as const

export type AccountStateEnum = (typeof AccountStateEnum)[keyof typeof AccountStateEnum]

/**
 *
 * @export
 * @interface AuthToken
 */
export interface AuthToken {
  /**
   *
   * @type {string}
   * @memberof AuthToken
   */
  accessToken?: string
  /**
   *
   * @type {string}
   * @memberof AuthToken
   */
  refreshToken?: string
}
/**
 *
 * @export
 * @interface Credentials
 */
export interface Credentials {
  /**
   *
   * @type {string}
   * @memberof Credentials
   */
  username?: string
  /**
   *
   * @type {string}
   * @memberof Credentials
   */
  password?: string
}
/**
 *
 * @export
 * @interface ExchangeRate
 */
export interface ExchangeRate {
  /**
   *
   * @type {string}
   * @memberof ExchangeRate
   */
  base?: string
  /**
   *
   * @type {string}
   * @memberof ExchangeRate
   */
  counter?: string
  /**
   *
   * @type {number}
   * @memberof ExchangeRate
   */
  ask?: number
  /**
   *
   * @type {number}
   * @memberof ExchangeRate
   */
  bid?: number
  /**
   *
   * @type {string}
   * @memberof ExchangeRate
   */
  origin?: string
}
/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  date?: string
}
/**
 *
 * @export
 * @interface PropertyType
 */
export interface PropertyType {
  /**
   *
   * @type {string}
   * @memberof PropertyType
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof PropertyType
   */
  name?: string
}
/**
 *
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
  /**
   *
   * @type {string}
   * @memberof RefreshToken
   */
  refreshToken?: string
}
/**
 *
 * @export
 * @interface Registration
 */
export interface Registration {
  /**
   *
   * @type {string}
   * @memberof Registration
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof Registration
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof Registration
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof Registration
   */
  password?: string
}
/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  period?: string
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  startDate?: string
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  currency?: string
}
/**
 *
 * @export
 * @interface Transaction
 */
export interface Transaction {
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  fromWalletId?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  toWalletId?: string
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  fromAmount?: number
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  toAmount?: number
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  tradeDate?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  settlementDate?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  ref?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  asset?: string
  /**
   *
   * @type {boolean}
   * @memberof Transaction
   */
  completed?: boolean
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  username?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  password?: string
  /**
   * user state
   * @type {string}
   * @memberof User
   */
  state?: UserStateEnum
}

export const UserStateEnum = {
  Registered: 'REGISTERED',
  Active: 'ACTIVE',
  Deleted: 'DELETED',
} as const

export type UserStateEnum = (typeof UserStateEnum)[keyof typeof UserStateEnum]

/**
 *
 * @export
 * @interface Wallet
 */
export interface Wallet {
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  accountId?: string
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  currency?: string
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  balance?: number
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add new account for specific user
     * @summary Add new user account
     * @param {Account} account create new account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAccount: async (
      account: Account,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'account' is not null or undefined
      assertParamExists('addAccount', 'account', account)
      const localVarPath = `/accounts`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        account,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary delete account
     * @param {string} accountId Account to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccount: async (
      accountId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('deleteAccount', 'accountId', accountId)
      const localVarPath = `/accounts/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get user account details
     * @summary Get account details
     * @param {string} accountId Account for editing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountDetails: async (
      accountId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('getAccountDetails', 'accountId', accountId)
      const localVarPath = `/accounts/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get list of accounts
     * @summary Get all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/accounts`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update an existing account
     * @param {string} accountId Account for editing.
     * @param {Account} account Account object that needs to be added to the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount: async (
      accountId: string,
      account: Account,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('updateAccount', 'accountId', accountId)
      // verify required parameter 'account' is not null or undefined
      assertParamExists('updateAccount', 'account', account)
      const localVarPath = `/accounts/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        account,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
  return {
    /**
     * Add new account for specific user
     * @summary Add new user account
     * @param {Account} account create new account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAccount(
      account: Account,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addAccount(
        account,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @summary delete account
     * @param {string} accountId Account to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAccount(
      accountId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(
        accountId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Get user account details
     * @summary Get account details
     * @param {string} accountId Account for editing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountDetails(
      accountId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountDetails(
        accountId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Get list of accounts
     * @summary Get all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccounts(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @summary Update an existing account
     * @param {string} accountId Account for editing.
     * @param {Account} account Account object that needs to be added to the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccount(
      accountId: string,
      account: Account,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(
        accountId,
        account,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
  }
}

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AccountsApiFp(configuration)
  return {
    /**
     * Add new account for specific user
     * @summary Add new user account
     * @param {Account} account create new account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAccount(account: Account, options?: any): AxiosPromise<void> {
      return localVarFp
        .addAccount(account, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary delete account
     * @param {string} accountId Account to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccount(accountId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteAccount(accountId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get user account details
     * @summary Get account details
     * @param {string} accountId Account for editing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountDetails(accountId: string, options?: any): AxiosPromise<Account> {
      return localVarFp
        .getAccountDetails(accountId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get list of accounts
     * @summary Get all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts(options?: any): AxiosPromise<Array<Account>> {
      return localVarFp.getAccounts(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update an existing account
     * @param {string} accountId Account for editing.
     * @param {Account} account Account object that needs to be added to the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(
      accountId: string,
      account: Account,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAccount(accountId, account, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
  /**
   * Add new account for specific user
   * @summary Add new user account
   * @param {Account} account create new account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public addAccount(account: Account, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .addAccount(account, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary delete account
   * @param {string} accountId Account to delete.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public deleteAccount(accountId: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .deleteAccount(accountId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get user account details
   * @summary Get account details
   * @param {string} accountId Account for editing.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAccountDetails(accountId: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .getAccountDetails(accountId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get list of accounts
   * @summary Get all accounts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAccounts(options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .getAccounts(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update an existing account
   * @param {string} accountId Account for editing.
   * @param {Account} account Account object that needs to be added to the app
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public updateAccount(
    accountId: string,
    account: Account,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .updateAccount(accountId, account, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Logs user into the system with user credentials to get jwt token in respose
     * @summary Logs user into the system
     * @param {Credentials} credentials Login with user credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogin: async (
      credentials: Credentials,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'credentials' is not null or undefined
      assertParamExists('authLogin', 'credentials', credentials)
      const localVarPath = `/auth/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        credentials,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Replace refresh token on jwt tokens
     * @summary Refresh jwt tokens
     * @param {RefreshToken} refreshToken Refresh token from auth/login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authRefresh: async (
      refreshToken: RefreshToken,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshToken' is not null or undefined
      assertParamExists('authRefresh', 'refreshToken', refreshToken)
      const localVarPath = `/auth/refresh`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshToken,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Confirmation of ownership of email address
     * @summary Confirmation of email
     * @param {string} token Confirmation token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmEmail: async (
      token: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('confirmEmail', 'token', token)
      const localVarPath = `/auth/confirm`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration)

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
  return {
    /**
     * Logs user into the system with user credentials to get jwt token in respose
     * @summary Logs user into the system
     * @param {Credentials} credentials Login with user credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLogin(
      credentials: Credentials,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLogin(
        credentials,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Replace refresh token on jwt tokens
     * @summary Refresh jwt tokens
     * @param {RefreshToken} refreshToken Refresh token from auth/login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authRefresh(
      refreshToken: RefreshToken,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authRefresh(
        refreshToken,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Confirmation of ownership of email address
     * @summary Confirmation of email
     * @param {string} token Confirmation token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmEmail(
      token: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmail(
        token,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
  }
}

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthApiFp(configuration)
  return {
    /**
     * Logs user into the system with user credentials to get jwt token in respose
     * @summary Logs user into the system
     * @param {Credentials} credentials Login with user credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogin(credentials: Credentials, options?: any): AxiosPromise<AuthToken> {
      return localVarFp
        .authLogin(credentials, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Replace refresh token on jwt tokens
     * @summary Refresh jwt tokens
     * @param {RefreshToken} refreshToken Refresh token from auth/login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authRefresh(refreshToken: RefreshToken, options?: any): AxiosPromise<AuthToken> {
      return localVarFp
        .authRefresh(refreshToken, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Confirmation of ownership of email address
     * @summary Confirmation of email
     * @param {string} token Confirmation token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmEmail(token: string, options?: any): AxiosPromise<AuthToken> {
      return localVarFp
        .confirmEmail(token, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   * Logs user into the system with user credentials to get jwt token in respose
   * @summary Logs user into the system
   * @param {Credentials} credentials Login with user credentials
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLogin(credentials: Credentials, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authLogin(credentials, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Replace refresh token on jwt tokens
   * @summary Refresh jwt tokens
   * @param {RefreshToken} refreshToken Refresh token from auth/login
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authRefresh(refreshToken: RefreshToken, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authRefresh(refreshToken, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Confirmation of ownership of email address
   * @summary Confirmation of email
   * @param {string} token Confirmation token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public confirmEmail(token: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .confirmEmail(token, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * PropertiesApi - axios parameter creator
 * @export
 */
export const PropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * List of available property types
     * @summary Property types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPropertyTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/property-types`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PropertiesApi - functional programming interface
 * @export
 */
export const PropertiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PropertiesApiAxiosParamCreator(configuration)
  return {
    /**
     * List of available property types
     * @summary Property types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPropertyTypes(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyType>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyTypes(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
  }
}

/**
 * PropertiesApi - factory interface
 * @export
 */
export const PropertiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PropertiesApiFp(configuration)
  return {
    /**
     * List of available property types
     * @summary Property types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPropertyTypes(options?: any): AxiosPromise<Array<PropertyType>> {
      return localVarFp
        .getPropertyTypes(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * PropertiesApi - object-oriented interface
 * @export
 * @class PropertiesApi
 * @extends {BaseAPI}
 */
export class PropertiesApi extends BaseAPI {
  /**
   * List of available property types
   * @summary Property types
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropertiesApi
   */
  public getPropertyTypes(options?: AxiosRequestConfig) {
    return PropertiesApiFp(this.configuration)
      .getPropertyTypes(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Users notifications
     * @summary Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/notifications`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Exchange rates for currencies
     * @summary FX rates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/rates`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Users subscriptions
     * @summary Subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/subscriptions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
  return {
    /**
     * Users notifications
     * @summary Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotifications(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Exchange rates for currencies
     * @summary FX rates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRates(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExchangeRate>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRates(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Users subscriptions
     * @summary Subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptions(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Subscription>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptions(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
  }
}

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SystemApiFp(configuration)
  return {
    /**
     * Users notifications
     * @summary Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications(options?: any): AxiosPromise<Array<Notification>> {
      return localVarFp
        .getNotifications(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Exchange rates for currencies
     * @summary FX rates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRates(options?: any): AxiosPromise<Array<ExchangeRate>> {
      return localVarFp.getRates(options).then((request) => request(axios, basePath))
    },
    /**
     * Users subscriptions
     * @summary Subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptions(options?: any): AxiosPromise<Array<Subscription>> {
      return localVarFp
        .getSubscriptions(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
  /**
   * Users notifications
   * @summary Notifications
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public getNotifications(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .getNotifications(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Exchange rates for currencies
   * @summary FX rates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public getRates(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .getRates(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Users subscriptions
   * @summary Subscriptions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public getSubscriptions(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .getSubscriptions(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add transaction to blotter
     * @summary Add transaction
     * @param {string} transactionId transaction uuid
     * @param {Transaction} transaction order placed for purchasing the pet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTransaction: async (
      transactionId: string,
      transaction: Transaction,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('addTransaction', 'transactionId', transactionId)
      // verify required parameter 'transaction' is not null or undefined
      assertParamExists('addTransaction', 'transaction', transaction)
      const localVarPath = `/transactions/{transactionId}`.replace(
        `{${'transactionId'}}`,
        encodeURIComponent(String(transactionId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transaction,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete specified transaction
     * @summary Delete transaction by ID
     * @param {string} transactionId transaction uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransaction: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('deleteTransaction', 'transactionId', transactionId)
      const localVarPath = `/transactions/{transactionId}`.replace(
        `{${'transactionId'}}`,
        encodeURIComponent(String(transactionId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Find transaction details
     * @summary Find transaction details
     * @param {string} transactionId transaction uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionDetails: async (
      transactionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists('getTransactionDetails', 'transactionId', transactionId)
      const localVarPath = `/transactions/{transactionId}`.replace(
        `{${'transactionId'}}`,
        encodeURIComponent(String(transactionId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Returns a transaction blotter
     * @summary Transaction blotter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/transactions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
  return {
    /**
     * Add transaction to blotter
     * @summary Add transaction
     * @param {string} transactionId transaction uuid
     * @param {Transaction} transaction order placed for purchasing the pet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addTransaction(
      transactionId: string,
      transaction: Transaction,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addTransaction(
        transactionId,
        transaction,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Delete specified transaction
     * @summary Delete transaction by ID
     * @param {string} transactionId transaction uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTransaction(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransaction(
        transactionId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Find transaction details
     * @summary Find transaction details
     * @param {string} transactionId transaction uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactionDetails(
      transactionId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionDetails(
        transactionId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Returns a transaction blotter
     * @summary Transaction blotter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransactions(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Transaction>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactions(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
  }
}

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TransactionsApiFp(configuration)
  return {
    /**
     * Add transaction to blotter
     * @summary Add transaction
     * @param {string} transactionId transaction uuid
     * @param {Transaction} transaction order placed for purchasing the pet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTransaction(
      transactionId: string,
      transaction: Transaction,
      options?: any,
    ): AxiosPromise<Transaction> {
      return localVarFp
        .addTransaction(transactionId, transaction, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Delete specified transaction
     * @summary Delete transaction by ID
     * @param {string} transactionId transaction uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransaction(transactionId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteTransaction(transactionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Find transaction details
     * @summary Find transaction details
     * @param {string} transactionId transaction uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactionDetails(
      transactionId: string,
      options?: any,
    ): AxiosPromise<Transaction> {
      return localVarFp
        .getTransactionDetails(transactionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Returns a transaction blotter
     * @summary Transaction blotter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransactions(options?: any): AxiosPromise<Array<Transaction>> {
      return localVarFp
        .getTransactions(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
  /**
   * Add transaction to blotter
   * @summary Add transaction
   * @param {string} transactionId transaction uuid
   * @param {Transaction} transaction order placed for purchasing the pet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public addTransaction(
    transactionId: string,
    transaction: Transaction,
    options?: AxiosRequestConfig,
  ) {
    return TransactionsApiFp(this.configuration)
      .addTransaction(transactionId, transaction, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete specified transaction
   * @summary Delete transaction by ID
   * @param {string} transactionId transaction uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public deleteTransaction(transactionId: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .deleteTransaction(transactionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Find transaction details
   * @summary Find transaction details
   * @param {string} transactionId transaction uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getTransactionDetails(transactionId: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .getTransactionDetails(transactionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Returns a transaction blotter
   * @summary Transaction blotter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getTransactions(options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .getTransactions(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Register new user with minimum parameters
     * @summary Register new user
     * @param {Registration} registration Created user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      registration: Registration,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registration' is not null or undefined
      assertParamExists('createUser', 'registration', registration)
      const localVarPath = `/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        registration,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * This can only be done by the logged in user.
     * @summary Delete user
     * @param {string} username The name that needs to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser: async (
      username: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('deleteUser', 'username', username)
      const localVarPath = `/users/{username}`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Fetch user details from server
     * @summary Get user details
     * @param {string} username The username that needs to be fetched.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (
      username: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('getUser', 'username', username)
      const localVarPath = `/users/{username}`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
  return {
    /**
     * Register new user with minimum parameters
     * @summary Register new user
     * @param {Registration} registration Created user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      registration: Registration,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(
        registration,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * This can only be done by the logged in user.
     * @summary Delete user
     * @param {string} username The name that needs to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUser(
      username: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(
        username,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Fetch user details from server
     * @summary Get user details
     * @param {string} username The username that needs to be fetched.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      username: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(username, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
  }
}

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration)
  return {
    /**
     * Register new user with minimum parameters
     * @summary Register new user
     * @param {Registration} registration Created user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(registration: Registration, options?: any): AxiosPromise<User> {
      return localVarFp
        .createUser(registration, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * This can only be done by the logged in user.
     * @summary Delete user
     * @param {string} username The name that needs to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(username: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteUser(username, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Fetch user details from server
     * @summary Get user details
     * @param {string} username The username that needs to be fetched.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(username: string, options?: any): AxiosPromise<User> {
      return localVarFp
        .getUser(username, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * Register new user with minimum parameters
   * @summary Register new user
   * @param {Registration} registration Created user object
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUser(registration: Registration, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .createUser(registration, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * This can only be done by the logged in user.
   * @summary Delete user
   * @param {string} username The name that needs to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public deleteUser(username: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .deleteUser(username, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Fetch user details from server
   * @summary Get user details
   * @param {string} username The username that needs to be fetched.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUser(username: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getUser(username, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * WalletsApi - axios parameter creator
 * @export
 */
export const WalletsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add a new wallet to the account
     * @param {Wallet} wallet create new account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addWallet: async (
      wallet: Wallet,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'wallet' is not null or undefined
      assertParamExists('addWallet', 'wallet', wallet)
      const localVarPath = `/wallets`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        wallet,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Delete a wallet
     * @param {string} walletId Wallet id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWallet: async (
      walletId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('deleteWallet', 'walletId', walletId)
      const localVarPath = `/wallets/{walletId}`.replace(
        `{${'walletId'}}`,
        encodeURIComponent(String(walletId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Returns a single wallet details
     * @summary Wallet details
     * @param {string} walletId ID of wallet to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet: async (
      walletId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('getWallet', 'walletId', walletId)
      const localVarPath = `/wallets/{walletId}`.replace(
        `{${'walletId'}}`,
        encodeURIComponent(String(walletId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get list of user accounts
     * @summary Get all wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/wallets`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Update an existing wallet details
     * @param {string} walletId ID of wallet to return
     * @param {Wallet} wallet Update wallet details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWallet: async (
      walletId: string,
      wallet: Wallet,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists('updateWallet', 'walletId', walletId)
      // verify required parameter 'wallet' is not null or undefined
      assertParamExists('updateWallet', 'wallet', wallet)
      const localVarPath = `/wallets/{walletId}`.replace(
        `{${'walletId'}}`,
        encodeURIComponent(String(walletId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        wallet,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WalletsApi - functional programming interface
 * @export
 */
export const WalletsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WalletsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Add a new wallet to the account
     * @param {Wallet} wallet create new account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addWallet(
      wallet: Wallet,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Wallet>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addWallet(wallet, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @summary Delete a wallet
     * @param {string} walletId Wallet id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteWallet(
      walletId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWallet(
        walletId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Returns a single wallet details
     * @summary Wallet details
     * @param {string} walletId ID of wallet to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWallet(
      walletId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWallet(
        walletId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     * Get list of user accounts
     * @summary Get all wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWallets(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Wallet>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWallets(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @summary Update an existing wallet details
     * @param {string} walletId ID of wallet to return
     * @param {Wallet} wallet Update wallet details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateWallet(
      walletId: string,
      wallet: Wallet,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateWallet(
        walletId,
        wallet,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      )
    },
  }
}

/**
 * WalletsApi - factory interface
 * @export
 */
export const WalletsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WalletsApiFp(configuration)
  return {
    /**
     *
     * @summary Add a new wallet to the account
     * @param {Wallet} wallet create new account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addWallet(wallet: Wallet, options?: any): AxiosPromise<Array<Wallet>> {
      return localVarFp
        .addWallet(wallet, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Delete a wallet
     * @param {string} walletId Wallet id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWallet(walletId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteWallet(walletId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Returns a single wallet details
     * @summary Wallet details
     * @param {string} walletId ID of wallet to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet(walletId: string, options?: any): AxiosPromise<Wallet> {
      return localVarFp
        .getWallet(walletId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get list of user accounts
     * @summary Get all wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallets(options?: any): AxiosPromise<Array<Wallet>> {
      return localVarFp.getWallets(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Update an existing wallet details
     * @param {string} walletId ID of wallet to return
     * @param {Wallet} wallet Update wallet details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWallet(walletId: string, wallet: Wallet, options?: any): AxiosPromise<Wallet> {
      return localVarFp
        .updateWallet(walletId, wallet, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * WalletsApi - object-oriented interface
 * @export
 * @class WalletsApi
 * @extends {BaseAPI}
 */
export class WalletsApi extends BaseAPI {
  /**
   *
   * @summary Add a new wallet to the account
   * @param {Wallet} wallet create new account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public addWallet(wallet: Wallet, options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .addWallet(wallet, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete a wallet
   * @param {string} walletId Wallet id to delete
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public deleteWallet(walletId: string, options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .deleteWallet(walletId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Returns a single wallet details
   * @summary Wallet details
   * @param {string} walletId ID of wallet to return
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public getWallet(walletId: string, options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .getWallet(walletId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get list of user accounts
   * @summary Get all wallets
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public getWallets(options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .getWallets(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update an existing wallet details
   * @param {string} walletId ID of wallet to return
   * @param {Wallet} wallet Update wallet details
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletsApi
   */
  public updateWallet(walletId: string, wallet: Wallet, options?: AxiosRequestConfig) {
    return WalletsApiFp(this.configuration)
      .updateWallet(walletId, wallet, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
