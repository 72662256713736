import * as React from 'react'
import { useEffect, useState } from 'react'
import { Integration } from '../../model/modelTypes'
import IntegrationCard from './IntegrationCard'
import Grid from '@mui/material/Grid'
import useToken from '../../services/useToken'
import BackendApi from '../../services/backend'

export default function IntegrationsPage() {
  const { token } = useToken()
  const api = new BackendApi()

  const [rows, setRows] = useState([] as Integration[])

  useEffect(() => {
    api
      .getIntegrations(token)
      .then((rsp: Integration[]) => setRows(rsp))
      .catch((error: any) => {
        console.log(error)
      })
  }, [token])

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      // alignItems="center"
    >
      {rows.map((value: Integration) => (
        <Grid item sm style={{ width: '100%' }}>
          <IntegrationCard value={value} />
        </Grid>
      ))}
    </Grid>
  )
}
