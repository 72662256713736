import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import Stack from '@mui/material/Stack'

import data from '../../data/locale/en/data.json'
// import BackendApi, {NotificationSetting} from "../../services/backend";

export default function NotificationsPanel() {
  // const api = new BackendApi()
  // const notificationSettings = api.getNotificationSettings() as NotificationSetting[]

  const [emailSecurityUpdate, setEmailSecurityUpdate] = useState(false)

  const toggleEmailSecurityUpdate = () => {
    setEmailSecurityUpdate((prev) => !prev)
  }
  const [securityUpdate, setSecurityUpdate] = useState(false)

  const toggleSecurityUpdate = () => {
    setSecurityUpdate((prev) => !prev)
  }
  const [emailProductUpdates, setEmailProductUpdates] = useState(false)

  const toggleEmailProductUpdates = () => {
    setEmailProductUpdates((prev) => !prev)
  }
  const [productUpdates, setProductUpdates] = useState(false)

  const toggleProductUpdates = () => {
    setProductUpdates((prev) => !prev)
  }

  // notificationSettings.forEach(value => {
  //     if (value.notification === "email" && value.category === "product")
  //         setEmailProductUpdates(value.enabled)
  //     if (value.notification === "webPush" && value.category === "product")
  //         setProductUpdates(value.enabled)
  //     if (value.notification === "email" && value.category === "security")
  //         setEmailSecurityUpdate(value.enabled)
  //     if (value.notification === "webPush" && value.category === "security")
  //         setSecurityUpdate(value.enabled)
  // })

  return (
    <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
      <Stack
        direction="column"
        justifyContent="space-between"
        spacing={5}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        <Typography variant="h4" component="h2">
          {data.notifications.categories.product.title}
          <Typography variant="h6" component="h4">
            {data.notifications.categories.product.subtitle}
          </Typography>
        </Typography>
        <Typography variant="h4" component="h2">
          {data.notifications.categories.security.title}
          <Typography variant="h6" component="h4">
            {data.notifications.categories.security.subtitle}
          </Typography>
        </Typography>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        <Box flexGrow={1} flexDirection="column">
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={emailProductUpdates}
                  onChange={toggleEmailProductUpdates}
                />
              }
              label={data.notifications.types.email}
            />
            <Typography>{data.notifications.categories.product.subtitle}</Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={emailSecurityUpdate}
                  onChange={toggleEmailSecurityUpdate}
                />
              }
              label={data.notifications.types.webPush}
            />
            <Typography>{data.notifications.categories.product.subtitle}</Typography>
          </Box>
        </Box>
        <Box flexGrow={1} flexDirection="column">
          <Box>
            <FormControlLabel
              control={
                <Switch checked={productUpdates} onChange={toggleProductUpdates} />
              }
              label={data.notifications.types.email}
            />
            <Typography>{data.notifications.categories.product.subtitle}</Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch checked={securityUpdate} onChange={toggleSecurityUpdate} />
              }
              label={data.notifications.types.webPush}
            />
            <Typography>{data.notifications.categories.security.subtitle}</Typography>
          </Box>
        </Box>
      </Stack>
    </Stack>
  )
}
