import React, { useState } from 'react'
import Copyright from '../../components/Copyright'
import BackendApi from '../../services/backend'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Fade,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { AuthToken } from '../../api'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue?
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

interface State {
  username: string
  password: string
  showPassword: boolean
}

const api = new BackendApi()

// @ts-ignore
export default function LogIn({ setToken }) {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState<State>({
    username: '',
    password: '',
    showPassword: false,
  })

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('Cannot login, username or password is wrong!')

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading((prevLoading) => !prevLoading)
    await api
      .loginUser(values.username, values.password)
      // @ts-ignore
      .then((token: AuthToken) => setToken(token))
      .catch((reason: any) => {
        setLoading(() => false)
        setErrorMsg(reason)
        setError(true)
      })
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {error && <Alert severity="error">{errorMsg.toString()}</Alert>}
        {loading ? (
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        ) : (
          <Avatar className={classes.avatar}>
            <LockOpenIcon />
          </Avatar>
        )}
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange('username')}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              label="Password"
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant={loading ? 'outlined' : 'contained'}
            color="primary"
            className={classes.submit}
          >
            {loading ? 'Loading...' : 'Log In'}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href={'/forgot-password'} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href={'/sign-up'} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
LogIn.propTypes = {
  setToken: PropTypes.func.isRequired,
}
