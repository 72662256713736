import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

function Title(props: { children: React.ReactNode }) {
  return (
    <Typography
      component="h2"
      variant="h4"
      color="textSecondary"
      align={'center'}
      gutterBottom
    >
      {props.children}
    </Typography>
  )
}

Title.propTypes = {
  children: PropTypes.node,
}

export default Title
