import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import TuneIcon from '@mui/icons-material/Tune'
import {
  Avatar,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import BackendApi from '../../services/backend'
import AddPropertyDialog from '../../components/properties/AddPropertyDialog'
import data from '../../data/locale/en/data.json'
import paths from '../../data/paths.json'

const useStyles = makeStyles((theme: Theme) => ({
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
  ul: {
    padding: 0,
    'list-style-type': 'none',
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  root: {
    padding: theme.spacing(3),
  },
}))
export default function PropertyContractsPage() {
  const classes = useStyles()
  const history = useHistory()

  const api = new BackendApi()
  const properties = api.getPropertyContracts()

  let res = new Set<string>()
  properties.forEach((v) => {
    res.add(v.baseAsset.address.country)
  })
  const countries = Array.from(res)

  const [openAddProperty, setOpenAddProperty] = useState(false)
  const handleAddProperty = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    history.push('#new')
    setOpenAddProperty(true)
  }

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
        <Link underline="hover" color="inherit" component={RouterLink} to={paths.root}>
          <HomeIcon />
          {data.title.home}
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          <LocationOnIcon className={classes.icon} />
          {data.title.propertyContracts}
        </Typography>
      </Breadcrumbs>
      <Paper>
        <List subheader={<li />}>
          {countries.map((country) => (
            <li key={`section-${country}`}>
              <ul className={classes.ul}>
                <ListSubheader>{`${country}`}</ListSubheader>
                {properties.map(
                  (item) =>
                    item.baseAsset.address.country === country && (
                      <ListItem key={`item-${country}-${item.id}`}>
                        <ListItemAvatar>
                          <Tooltip
                            title={`${item.estimatedPayments.amount} ${item.estimatedPayments.currency}`}
                            aria-label="add"
                          >
                            <Avatar
                              alt={item.baseAsset.address.countryCode}
                              className={
                                item.side === 'BUY' ? classes.green : classes.red
                              }
                              src={`/flags/${item.baseAsset.address.countryCode
                                ?.substring(0, 2)
                                .toLowerCase()}.webp`}
                            />
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.baseAsset.name}
                          secondary={`${item.contractType} from ${item.startDate} till ${item.maturityDate}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            component={RouterLink}
                            to={paths.propertyDetails.replace(':id', item.id)}
                          >
                            <TuneIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ),
                )}
              </ul>
            </li>
          ))}
        </List>
      </Paper>
      <Stack direction="row" spacing={2}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ maxWidth: '200px' }}
          onClick={handleAddProperty}
        >
          {data.btn.addTenancy}
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="info"
          sx={{ maxWidth: '200px' }}
          component={RouterLink}
          to={paths.properties}
        >
          {data.title.properties}
        </Button>
      </Stack>
      {openAddProperty && (
        <AddPropertyDialog
          open={openAddProperty}
          setOpenAddProperty={setOpenAddProperty}
        />
      )}
    </Stack>
  )
}
