import { useState, useEffect } from 'react';

interface UserInfo {
  firstName: string;
  lastName: string;
}

const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>(() => {
    // Retrieve user info from local storage or set to default values
    const storedUserInfo = localStorage.getItem('userInfo');
    return storedUserInfo ? JSON.parse(storedUserInfo) : { firstName: 'Anton', lastName: 'Batiaev' };
  });

  // Update local storage whenever userInfo changes
  useEffect(() => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }, [userInfo]);

  // Function to update user info
  const updateUserInfo = (newUserInfo: Partial<UserInfo>) => {
    setUserInfo((prev) => ({
      ...prev,
      ...newUserInfo,
    }));
  };

  return {
    fullName: `${userInfo.firstName} ${userInfo.lastName}`,
    setUserInfo: updateUserInfo,
  };
};

export default useUserInfo;
