import React, { useState } from 'react'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DepthChartWidget from './widgets/DepthChartWidget'
import LineChartWidget from './widgets/LineChartWidget'
import CandleStickChartWidget from './widgets/CandleStickChartWidget'
import AssetStateWidget from '../AssetStateWidget'

export default function ChartComponent({
  defaultType = 'depth',
  defaultShowStats = false,
}) {
  const [showStats, setShowStats] = useState(defaultShowStats)
  const [type, setType] = useState(defaultType)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <BarChartIcon />
          </Avatar>
        }
        action={
          <IconButton
            id="basic-button"
            aria-label="settings"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => handleClick(event)}
          >
            <MoreVertIcon />
          </IconButton>
        }
        title={
          type === 'depth'
            ? 'Depth chart'
            : type === 'candle'
            ? 'Candle stick chart'
            : 'Line chart'
        }
        // subheader={new Date().toDateString()}
      />
      <CardContent>
        {showStats && <AssetStateWidget />}
        {type === 'depth' && <DepthChartWidget />}
        {type === 'candle' && <CandleStickChartWidget />}
        {type === 'line' && <LineChartWidget />}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem>
            <Container>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Chart type</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={type}
                  name="radio-buttons-group"
                  onChange={(event, value) => setType(value)}
                >
                  <FormControlLabel value="depth" control={<Radio />} label="Depth" />
                  <FormControlLabel value="candle" control={<Radio />} label="Candle" />
                  <FormControlLabel value="line" control={<Radio />} label="Line" />
                </RadioGroup>
              </FormControl>
            </Container>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowStats(!showStats)
            }}
          >
            <ListItemIcon>
              <Checkbox edge="start" checked={showStats} tabIndex={-1} disableRipple />
            </ListItemIcon>
            Show stats
          </MenuItem>
        </Menu>
      </CardContent>
    </Card>
  )
}
