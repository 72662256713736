import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { AlertColor, Avatar, TextField } from '@mui/material'
import BalanceIcon from '@mui/icons-material/Balance'
import { WalletsApi } from 'fintecy'
import useToken from '../../../services/useToken'
import paths from '../../../data/paths.json'
import BackendApi, { Wallet } from '../../../services/backend'
import data from '../../../data/locale/en/data.json'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { GridColDef } from '@mui/x-data-grid'
import FintecyDataGrid from '../../../components/FintecyDataGrid'

function WalletsPage() {
  const { token } = useToken()
  const api = new BackendApi()

  const [msg, setMsg] = useState(
    data.msg.recordDeleted.replace('$type', data.wallets.title) as string,
  )
  const [alertType, setAlertType] = useState('success' as AlertColor)
  const [open, setOpen] = useState(false)

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') setOpen(false)
  }

  const [rows, setRows] = React.useState([] as Wallet[])
  useEffect(() => {
    const walletsApi = new WalletsApi()

    const options = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    walletsApi
      .getWallets(options)
      .then((rsp: any) => setRows(rsp.data as Wallet[]))
      .catch((error: any) => {
        setAlertType('warning')
        setMsg(
          data.msg.failToLoadData.replace('$type', data.transactions.title) +
            ': ' +
            error,
        )
        setOpen(true)
      })
  }, [token, open])

  const [openDialog, setOpenDialog] = useState(false)
  const [updatedBalance, setUpdatedBalance] = useState(0)
  const [originalBalance, setOriginalBalance] = useState(0)
  const [currentWallet, setCurrentWallet] = useState('')

  const updateBalance = () => {
    api.updateWalletBalance(currentWallet, updatedBalance, token).then((value) => {
      if (value.status === 200) {
        setOpenDialog(false)
        setAlertType('success')
        setMsg(data.msg.recordUpdated.replace('$type', data.wallets.title))
        setOpen(true)
      } else {
        setAlertType('warning')
        setMsg(value.data)
        setOpen(true)
      }
    })
  }
  const handleClickOpen = (walletId: string, balance: number) => {
    setCurrentWallet(walletId)
    setUpdatedBalance(balance)
    setOriginalBalance(balance)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  async function deleteWallet(id: string) {
    api
      .deleteWallet(id, token)
      .then(() => {
        setAlertType('success')
        setMsg(data.msg.recordDeleted.replace('$type', data.wallets.title))
        setOpen(true)
      })
      .catch((reason) => {
        setMsg(reason)
        setAlertType('warning')
        setOpen(true)
      })
  }

  // @ts-ignore
  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: data.table.actions,
      description: 'supported operations',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <ButtonGroup variant="text" color="primary" aria-label="wallets controls">
          <Tooltip title={data.wallets.btn.fixBalance}>
            <IconButton
              onClick={() => handleClickOpen(params.row.id, params.row.balance)}
            >
              <BalanceIcon />
            </IconButton>
          </Tooltip>
          <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>{data.wallets.dialog.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {data.wallets.dialog.content
                  .replace('$balance', `${originalBalance || 0}`)
                  .replace('$currency', `${params.row.currency}`)}
              </DialogContentText>
              <TextField
                autoFocus
                value={updatedBalance}
                onChange={(event) =>
                  setUpdatedBalance(event.target.value as unknown as number)
                }
                margin="dense"
                id="newAmount"
                label={data.wallets.field.newBalance}
                type="number"
                fullWidth
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{data.btn.cancel}</Button>
              <Button onClick={() => updateBalance()}>{data.btn.update}</Button>
            </DialogActions>
          </Dialog>
          <Tooltip title={data.btn.update}>
            <IconButton
              component={Link}
              to={paths.editWallet.replace(':id', params.row.id || paths.new)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={data.btn.delete}>
            <IconButton onClick={() => deleteWallet(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
    {
      field: 'name',
      headerName: data.wallets.field.name,
      flex: 2,
      type: 'string',
      editable: false,
    },
    {
      field: 'code',
      headerName: data.wallets.field.code,
      flex: 2,
      type: 'string',
      editable: false,
    },
    {
      field: 'type',
      headerName: data.wallets.field.type,
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'balance',
      headerName: data.wallets.field.balance,
      type: 'number',
      flex: 1,
      // editable: true,
    },
    {
      field: 'ccy',
      headerName: data.wallets.field.currency,
      description: 'ISO country code',
      type: 'string',
      sortable: true,
      renderCell: (params) => params.row.currency,
    },
    {
      field: 'currency',
      headerName: data.wallets.field.currency,
      description: 'ISO country code',
      type: 'string',
      sortable: true,
      renderCell: (params) => (
        <Avatar
          alt={params.row.currency}
          src={`/flags/${params.row.currency?.substring(0, 2).toLowerCase()}.webp`}
        />
      ),
    },
  ]
  return (
    <FintecyDataGrid
      title={data.wallets.title}
      addNewLink={paths.addWallet}
      rows={rows}
      columns={columns}
      openSnackbar={open}
      handleSnackbarClose={handleSnackbarClose}
      alertType={alertType}
      msg={msg}
    />
  )
}

export default WalletsPage
