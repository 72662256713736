import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ListIcon from '@mui/icons-material/List'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import { DesktopOnly, MobileOnly } from '../MobileDesktopVisibility'
import data from '../../data/locale/en/data.json'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import BackendApi from '../../services/backend'
import { Side } from '../../model/modelTypes'

export default function TradesComponent() {
  const api = new BackendApi()
  const orders = api.getOrders()
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <ListIcon />
          </Avatar>
        }
        // action={
        //     <IconButton aria-label="settings">
        //         <MoreVertIcon/>
        //     </IconButton>
        // }
        title={data.title.trades}
        // subheader={product}
      />
      <CardContent>
        <DesktopOnly>
          <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 200 }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Side</TableCell>
                  <TableCell align="right">State</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Time</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Amount (total / remaining)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row) => (
                  <TableRow
                    key={row.price + row.time}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{row.side}</TableCell>
                    <TableCell align="right">{row.state}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{row.time}</TableCell>
                    <TableCell align="right" component="th" scope="row">
                      {row.price}
                    </TableCell>
                    <TableCell align="right">
                      {row.amount + ' / ' + row.remaining}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DesktopOnly>
        <MobileOnly>
          <List sx={{ width: '100%' }}>
            {orders.map((row, idx: number) => (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      alt={row.side}
                      sx={{ bgcolor: row.side === Side.BUY ? 'green' : 'red' }}
                    >
                      {row.side === Side.BUY ? <TrendingUpIcon /> : <TrendingDownIcon />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={row.price + ' • ' + row.type}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {row.side + ' ' + row.remaining + ' @ ' + row.time}
                        </Typography>
                        <Typography>
                          {row.state + ' (total=' + row.amount + ')'}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ))}
          </List>
        </MobileOnly>
      </CardContent>
    </Card>
  )
}
