export type UUID = string

export interface Integration {
  id: string
  type: string
  createdDate: string
  updatedAt: string
  enabled: boolean
  credentials: IntegrationCredential[]
}

export interface IntegrationCredential {
  name: string
  value: string
}

export interface Transaction {
  id: number
  date: string
  name: string
  shipTo: string
  paymentMethod: string
  amount: number
}

export default interface User {
  avatar?: string
  firstName: string
  lastName: string
  email: string
  username: string
}

export enum Side {
  BUY = 'BUY',
  SELL = 'SELL',
}

export type ContractType = 'rent'
export type AssetType = 'apartment'

export interface Address {
  countryCode?: string
  country: string
  city: string
  region: string
}

export interface Property {
  id: string
  type: AssetType
  name: string
  address: Address
}

export interface Money {
  amount: number
  currency: string
}

export interface PropertyContract extends Contract {
  side: Side
  contractType: ContractType
  baseAsset: Property
  estimatedPayments: Money
}

export type PaymentType = 'FIXED' | 'FLOATING'

export interface Money {
  amount: number
  currency: string
}

export interface Payment {
  type: PaymentType
  name: string
  amount: Money
  rate?: number
  date: string
  id?: string
}

export interface Contract {
  id: string
  startDate: Date
  endDate: Date
  maturityDate: string
  asset: Property
  payments: Payment[]
}

export interface Measure {
  id?: string
  date: string
  type: string
  tariff?: string
  value: number
}

export interface Bbo {
  bid: number
  ask: number
}
