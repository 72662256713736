import { Link as RouterLink, useParams } from 'react-router-dom'
import PropertyDetails from './PropertyDetails'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BackendApi from '../../services/backend'
import { PropertyContract } from '../../model/modelTypes'
import paths from '../../data/paths.json'
import properties from '../../_mocks/properties.json'
import data from '../../data/locale/en/data.json'
import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useToken from '../../services/useToken'

// @ts-ignore
export default function PropertyDetailsPage() {
  const { token } = useToken()
  const api = new BackendApi()
  // @ts-ignore
  let { propertyId } = useParams()
  const [prop, setProp] = useState<PropertyContract>(
    // @ts-ignore
    properties.find((e) => e.id === propertyId) as PropertyContract,
  )

  useEffect(() => {
    api
      .getPropertyContractDetails(token, propertyId)
      .then((value) => {
        setProp(value)
      })
      .catch((reason) => {
        console.log(reason)
      })
  })

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" component={RouterLink} to={paths.root}>
          <HomeIcon />
          {data.title.home}
        </Link>
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to={paths.properties}
        >
          <LocationOnIcon />
          {data.title.properties}
        </Link>
        <Typography color="text.primary">
          <ApartmentIcon />
          {prop?.baseAsset.name}
        </Typography>
      </Breadcrumbs>
      <PropertyDetails property={prop as PropertyContract} />
    </Stack>
  )
}
