import { useState, useEffect } from 'react';

interface Transaction {
    date: string;
    amount: number;
    currency: string;
    source: string;
    category: string;
}

interface ExpenseData {
    categories: string[];
    amounts: number[];
    txs: Transaction[];
}

const useTransactions = (): ExpenseData | null => {
    const [expenseData, setExpenseData] = useState<ExpenseData | null>(null);

    // Test data for simulation
    const testData: Transaction[] = [
        { date: '2024-11-01', amount: 300, currency: 'USD', source: 'Bank Card', category: 'Salary' },
        { date: '2024-11-02', amount: -50, currency: 'USD', source: 'Bank Card', category: 'Groceries' },
        { date: '2024-11-03', amount: -20, currency: 'USD', source: 'Bank Card', category: 'Transport' },
        { date: '2024-11-04', amount: -100, currency: 'USD', source: 'Bank Card', category: 'Entertainment' },
        { date: '2024-11-05', amount: -75, currency: 'USD', source: 'Bank Card', category: 'Groceries' },
    ];

    useEffect(() => {
        // Process test data to group expenses by category (excluding positive amounts)
        const expensesByCategory = testData.reduce((acc, tx) => {
            if (tx.amount < 0) {
                const category = tx.category;
                acc[category] = (acc[category] || 0) + Math.abs(tx.amount);
            }
            return acc;
        }, {} as Record<string, number>);

        // Prepare data for use in charts
        const categories = Object.keys(expensesByCategory);
        const amounts = Object.values(expensesByCategory);

        setExpenseData({ categories, amounts, txs: testData });
    }, []);

    return expenseData;
};

export default useTransactions;
