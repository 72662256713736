import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import { red } from '@mui/material/colors'
import ReceiptIcon from '@mui/icons-material/Receipt'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import TimelineIcon from '@mui/icons-material/Timeline'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '../../components/TabPanel'
import { useHistory } from 'react-router-dom'
import { FC, useState } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { Property, PropertyContract } from '../../model/modelTypes'
import useMediaQuery from '@mui/material/useMediaQuery'
import paths from '../../data/paths.json'
import BackendApi from '../../services/backend'
import AddMeasureDialog from '../../components/properties/AddMeasureDialog'
import { SelectChangeEvent } from '@mui/material/Select'

export function a11yProps(index: string) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    '& > .MuiTab-wrapper': {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      // flexDirection: 'row',
      justifyContent: 'center',
      '& *': {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
    },
  },
  cardSpacing: {
    margin: theme.spacing(2),
    minWidth: 250,
    width: '100%',
  },
  table: {
    minWidth: 100,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expandBtn: {
    marginLeft: 'auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

interface PropertyProps {
  property: PropertyContract
}

// @ts-ignore
const PropertyDetails: FC<PropertyProps> = ({ property }) => {
  const classes = useStyles()
  const history = useHistory()
  const api = new BackendApi()
  const properties = api.getPropertyContracts()

  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [openAddMeasure, setOpenAddMeasure] = useState(false)
  const handleAddMeasureOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    history.push('#addMeasure')
    setOpenAddMeasure(true)
  }

  const [openAddPayment, setOpenAddPayment] = useState(false)
  const handleAddPaymentOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    history.push('#addPayment')
    setOpenAddPayment(true)
  }

  const handleAddPaymentClose = () => {
    setOpenAddPayment(false)
    history.push(paths.propertyPayments.replace(':id', property.id))
  }

  let res = new Set<Property>()
  properties.forEach((v) => {
    res.add(v.baseAsset as Property)
  })
  const assets = Array.from(res)
  console.log(assets)
  const [value, setValue] = useState(
    (window.location.hash === undefined || window.location.hash.length === 0
      ? '#general'
      : window.location.hash
    ).substring(1),
  )
  console.log(`Load tab ${value} for property ${property.baseAsset.name}`)

  const handleChange = (_event: any, newValue: React.SetStateAction<string>) => {
    setValue(newValue)
    history.push(`#${newValue}`)
    console.log('acive tab changed from ' + value + ' to ' + newValue)
  }

  const [state, setState] = useState<{ age: string | number; name: string }>({
    age: '',
    name: 'hai',
  })

  const saveChanges = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event)
  }
  const handleAssetChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.name as keyof typeof state
    setState({
      ...state,
      [name]: event.target.value,
    })
  }
  return (
    <Paper>
      <Tabs
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="tabs example"
      >
        <Tab
          label="General"
          value={'general'}
          icon={<ReceiptIcon />}
          className={classes.tab}
          {...a11yProps('general')}
        />
        <Tab
          label="Payments"
          value={'payments'}
          icon={<SyncAltIcon />}
          className={classes.tab}
          {...a11yProps('payments')}
        />
        <Tab
          label="Measures"
          value={'measures'}
          icon={<TimelineIcon />}
          className={classes.tab}
          {...a11yProps('measures')}
        />
      </Tabs>
      <TabPanel value={value} index={'general'}>
        <Stack spacing={2} component="form">
          <TextField
            // autoFocus
            required
            fullWidth
            disabled
            variant="outlined"
            name="id"
            id="id"
            label="Id"
            defaultValue={property.id}
          />
          <FormControl variant="outlined" fullWidth required>
            <InputLabel htmlFor="sideEditor">Side</InputLabel>
            <Select
              defaultValue={property.side}
              // onChange={handleAssetChange}
              label="Side"
              id="sideEditor"
            >
              <MenuItem value="BUY">BUY</MenuItem>
              <MenuItem value="SELL">SELL</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel htmlFor="contractTypeEditor">Contract type</InputLabel>
            <Select
              defaultValue={property.contractType}
              // onChange={handleAssetChange}
              label="Contract type"
              id="contractTypeEditor"
            >
              <MenuItem value="Rent">Rent</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            fullWidth
            type="date"
            variant="outlined"
            name="startDate"
            id="startDate"
            label="Start date"
            defaultValue={property.startDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            fullWidth
            type="date"
            variant="outlined"
            name="maturityDate"
            id="maturityDate"
            label="Maturity date"
            defaultValue={property.maturityDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="asset-native-simple">Asset</InputLabel>
            <Select
              // fullWidth
              defaultValue={property.baseAsset.id}
              onChange={handleAssetChange}
              label="Asset"
              id="asset-native-simple"
            >
              {assets.map((a) => (
                <MenuItem value={a.id}>{a.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            // fullWidth
            sx={{ maxWidth: '200px' }}
            variant="contained"
            color="primary"
            onClick={saveChanges}
          >
            Change details
          </Button>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={'payments'}>
        <Stack spacing={2}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {property.payments!.map((row: any) => (
                <TableRow key={row.type}>
                  <TableCell component="th" scope="row">
                    {row.date}
                  </TableCell>
                  {/* <TableCell>{row.date}</TableCell> */}
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">{row.thisMonth}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            variant="contained"
            color="primary"
            sx={{ maxWidth: '200px' }}
            href={paths.addPayment.replace(':id', property.id)}
            onClick={handleAddPaymentOpen}
          >
            Add payment
          </Button>
        </Stack>
        <Dialog
          fullScreen={fullScreen}
          open={openAddPayment}
          onClose={handleAddPaymentClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{'Add new payment'}</DialogTitle>
          <DialogContent>
            <Stack spacing={2} component="form">
              <DialogContentText>
                Add payment related to tenancy agreement.
              </DialogContentText>
              <TextField
                autoFocus
                required
                fullWidth
                type="date"
                variant="outlined"
                name="date"
                id="date"
                label="Date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="asset-native-simple">Type</InputLabel>
                <Select
                  fullWidth
                  required
                  onChange={handleAssetChange}
                  label="Type"
                  id="asset-native-simple"
                >
                  <MenuItem value={'Rent'}>Rent</MenuItem>
                  <MenuItem value={'Electricity'}>Electricity</MenuItem>
                  <MenuItem value={'Gas'}>Gas</MenuItem>
                  <MenuItem value={'Water'}>Water</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                fullWidth
                disabled
                type="number"
                variant="outlined"
                name="measure"
                id="measure"
                label="Last measure"
                defaultValue={'0'}
              />
              <TextField
                required
                fullWidth
                disabled
                type="number"
                variant="outlined"
                name="tariff"
                id="tariff"
                label="Tariff"
                defaultValue={'1'}
              />
              <TextField
                required
                fullWidth
                type="number"
                variant="outlined"
                name="value"
                id="value"
                label="Value"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddPaymentClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleAddPaymentClose}
              color="primary"
              variant="contained"
              autoFocus
            >
              Add payment
            </Button>
          </DialogActions>
        </Dialog>
      </TabPanel>
      <TabPanel value={value} index={'measures'}>
        <Stack spacing={2}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">
                  Amount
                  <br />
                  (Tariff)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {property.payments!.map((row: any) => (
                <TableRow key={row.type}>
                  <TableCell component="th" scope="row">
                    {row.date}
                  </TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">
                    {row.tariff}
                    <br />({row.thisMonth})
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            variant="contained"
            color="primary"
            sx={{ maxWidth: '200px' }}
            href={paths.addMeasure.replace(':id', property.id)}
            onClick={handleAddMeasureOpen}
          >
            Add measure
          </Button>
        </Stack>
        <AddMeasureDialog
          propertyId={property.id}
          open={openAddMeasure}
          setOpenAddMeasure={setOpenAddMeasure}
        />
      </TabPanel>
    </Paper>
  )
}
export default PropertyDetails
