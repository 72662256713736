import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import ReceiptIcon from '@mui/icons-material/Receipt'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ShareIcon from '@mui/icons-material/Share'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper/Paper'
import GeneralUserInfo from './GeneralUserInfoPanel'
import PasswordChangePanel from './PasswordChangePanel'
import { useHistory } from 'react-router-dom'
import BillingPanel from './BillingPanel'
import NotificationsPanel from './NotificationsPanel'
import SocialLinksPanel from './SocialLinksPanel'
import TabPanel from '../../components/TabPanel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings'
import features from '../../data/features.json'

export function a11yProps(index: string) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    '& > .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'center',
      '& *': {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
    },
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  root: {
    padding: theme.spacing(3),
  },
}))
export default function UserDetailsPage() {
  const classes = useStyles()
  const history = useHistory()
  const tabs = [
    {
      enabled: features.user.general,
      label: 'General',
      value: 'general',
      icon: <AccountBoxIcon />,
      panel: <GeneralUserInfo />,
      index: 0,
    },
    {
      enabled: features.user.billing,
      label: 'Billing',
      value: 'billing',
      icon: <ReceiptIcon />,
      panel: <BillingPanel />,
      index: 1,
    },
    {
      enabled: features.user.notifications,
      label: 'Notifications',
      value: 'notifications',
      icon: <NotificationsIcon />,
      panel: <NotificationsPanel />,
      index: 2,
    },
    {
      enabled: features.user.socialLink,
      label: 'Social links',
      value: 'socialLinks',
      icon: <ShareIcon />,
      panel: <SocialLinksPanel />,
      index: 3,
    },
    {
      enabled: features.user.security,
      label: 'Security',
      value: 'security',
      icon: <VpnKeyIcon />,
      panel: <PasswordChangePanel />,
      index: 4,
    },
  ]
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(
    (window.location.hash === undefined ? tabs[0].value : window.location.hash).substring(
      1,
    ),
  )
  // eslint-disable-next-line no-unused-vars
  const handleChange = (_event: any, newValue: React.SetStateAction<string>) => {
    setValue(newValue)
    history.push(`#${newValue}`)
    console.log('acive tab changed from ' + value + ' to ' + newValue)
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
          <Link
            color="inherit"
            href="/"
            onClick={(event) => {
              event.preventDefault()
              history.push(`/`)
            }}
            className={classes.link}
          >
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            <SettingsIcon className={classes.icon} />
            Settings
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Paper square>
          <Tabs
            value={value}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="tabs example"
          >
            {tabs.map(
              (t) =>
                t.enabled && (
                  <Tab
                    label={t.label}
                    value={t.value}
                    icon={t.icon}
                    className={classes.tab}
                    {...a11yProps(t.value)}
                  />
                ),
            )}
          </Tabs>
          {tabs.map(
            (t) =>
              t.enabled && (
                <TabPanel value={value} index={t.value}>
                  {t.panel}
                </TabPanel>
              ),
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}
