import Grid from '@mui/material/Grid'
import NotificationList from '../components/NotificationList'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { useHistory } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  root: {
    padding: theme.spacing(3),
  },
}))
export default function NotificationsPage() {
  const classes = useStyles()
  const history = useHistory()
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
          <Link
            color="inherit"
            href="/"
            onClick={(event) => {
              event.preventDefault()
              history.push(`/`)
            }}
            className={classes.link}
          >
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            <NotificationsActiveIcon className={classes.icon} />
            Notifications
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <NotificationList />
      </Grid>
    </Grid>
  )
}
