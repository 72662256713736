import data from '../data/locale/en/data.json'
import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Title from './Title'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { DataGrid } from '@mui/x-data-grid'
import { Alert, Snackbar } from '@mui/material'

// function FintecyDataGrid({children, title, addNewLink, rows, columns,openSnackbar,handleSnackbarClose,alertType,msg, ...props}) {

// @ts-ignore
function FintecyDataGrid({ title, addNewLink, rows, columns, openSnackbar, handleSnackbarClose, alertType, msg, ...props }) {
  const [pageSize, setPageSize] = useState<number>(25)
  const rowsPerPageOptions = [5, 10, 20, 25, 50, 100]
  return (
    <Card>
      <CardContent>
        <Title>
          {title}
          &nbsp;&nbsp;&nbsp;
          {addNewLink !== undefined && (
            <Button
              component={Link}
              to={addNewLink}
              size="small"
              startIcon={<AddCircleIcon />}
            >
              {data.btn.addNew}
            </Button>
          )}
        </Title>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={rowsPerPageOptions}
          checkboxSelection
          disableSelectionOnClick
          {...props}
          // experimentalFeatures={{newEditingApi: true}}
        />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={alertType}
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
        {/*{children}*/}
      </CardContent>
    </Card>
  )
}

export default FintecyDataGrid
