import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Title from '../../components/Title'
import CardActions from '@mui/material/CardActions'
import paths from '../../data/paths.json'

import { Subscription, SystemApi } from '../../api'
import useToken from '../../services/useToken'

export default function SubscriptionsPage() {
  const { token } = useToken()

  const [subscriptions, setSubscriptions] = React.useState([] as Subscription[])
  React.useEffect(() => {
    const api = new SystemApi()

    const options = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    api
      .getSubscriptions(options)
      .then((rsp: any) => setSubscriptions(rsp.data as Subscription[]))
      .catch((error: any) => {
        console.log(error)
      })
  }, [token])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Title>Subscriptions</Title>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.period}</TableCell>
                    <TableCell>{row.startDate}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.currency}</TableCell>
                    <TableCell>
                      <ButtonGroup
                        variant="text"
                        color="primary"
                        aria-label="text primary button group"
                      >
                        <Link to={paths.subscriptions + '/' + row.name}>
                          <Button>Edit</Button>
                        </Link>
                        <Link
                          to={paths.subscriptions + '/delete?subscription=' + row.name}
                        >
                          <Button>Delete</Button>
                        </Link>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
          <CardActions>
            <ButtonGroup
              variant="text"
              color="primary"
              aria-label="text primary button group"
            >
              <Link to={paths.addSubscription}>
                <Button size="small">Add subscription</Button>
              </Link>
            </ButtonGroup>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
